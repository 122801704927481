import { memo } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { FormLabel, LiteralValue } from 'subFramework'
import styles from './input.module.scss'

const handleInputChange = (e, onChange, onlyNumeric) => {
  if (onlyNumeric && !/^-?\d*[.]?\d{0,2}$/.test(e.target.value)) {
    return false
  }

  if (onlyNumeric && e.target.value * 1 > Number.MAX_SAFE_INTEGER - 1) {
    return false
  }

  if (onChange instanceof Function) {
    onChange(e)
  }

  return null
}

const Input = ({
  label,
  inputRef,
  disabled,
  onChange,
  onlyNumeric,
  error,
  literal,
  value,
  className,
  labelClassName,
  readOnly,
  type,
  ...props
}) =>
  literal ? (
    <LiteralValue label={label} value={value} type={type} />
  ) : (
    <label className={styles.label}>
      {label && (
        <FormLabel className={labelClassName} label={label} disabled={disabled || readOnly} />
      )}
      <input
        className={c(styles.input, error && styles.error, className !== '' && className)}
        onChange={(e) => handleInputChange(e, onChange, onlyNumeric)}
        disabled={disabled}
        readOnly={readOnly}
        ref={inputRef}
        value={value}
        type={type}
        {...props}
      />
    </label>
  )

Input.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onlyNumeric: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  literal: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
}

Input.defaultProps = {
  onlyNumeric: false,
  disabled: false,
  inputRef: null,
  onChange: null,
  literal: false,
  className: '',
  labelClassName: '',
  error: false,
  label: '',
  value: '',
  readOnly: false,
  type: null,
}

export default memo(Input)
