import { useState } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import { useRootStore } from 'providers/RootProvider'
import Meta from 'presentation/Meta'
import InputWrapper from 'subFramework/presentation/InputWrapper'
import Button from 'subFramework/presentation/Button'
import Logotype from 'presentation/Logotype'
import useToast from 'util/useToast'
import { MESSAGE_TYPE } from 'util/constants'
import RecoverPassFormStore from './RecoverPassFormStore'

import styles from './recoverPassScene.module.scss'

const RecoverPassScene = () => {
  const { t } = useTranslation('Login')
  const successToast = useToast({
    type: MESSAGE_TYPE.SUCCESS,
    staticMessage: t('toast.success.recoveryMessage'),
    staticTitle: t('toast.success.recoveryTitle'),
  })
  const errorToast = useToast({ type: MESSAGE_TYPE.ERROR })

  const { authStore } = useRootStore()

  const [recoverPassFormStore] = useState(new RecoverPassFormStore(authStore))
  const { email, errors, isLoading } = recoverPassFormStore

  const history = useHistory()

  const handleSuccess = () => {
    successToast()
    history.push('login')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      recoverPassFormStore.recoverPassword(handleSuccess, errorToast)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Meta title="Slingshot" />
        <div className={styles.column}>
          <div className={styles.logo}>
            <Logotype />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.inputs}>
            <InputWrapper
              inputStore={email}
              wrapperClassName={styles['input__user--wrapper']}
              className={styles.input__user}
              onChange={({ target }) => email.setValue(target.value)}
              onKeyPress={handleKeyPress}
              label={t('EnterUser')}
              disabled={isLoading}
              required
            />
            <div className={styles.errors}>
              {errors.map((error) => (
                <small key={uuid()}>{error}</small>
              ))}
            </div>
            <div className={styles.buttons}>
              <Button
                fullWidth
                big
                label={t('PasswordRecovery')}
                onClick={() => recoverPassFormStore.recoverPassword(handleSuccess, errorToast)}
                as="button"
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(RecoverPassScene)
