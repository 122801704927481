import { memo } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

const CustomPropTypes = {
  notAllowed: (propValue, key, componentName) =>
    propValue[key] != null &&
    new Error(`Invalid prop '${key}' supplied to '${componentName}'. Validation failed.`),
}

const SvgBaseIcon = ({ children, className, size, width, height, color, ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    className={c('mdi-icon', className)}
    width={width ?? size}
    height={height ?? size}
    fill={color}
  >
    {children}
  </svg>
)

SvgBaseIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  viewBox: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  xmlns: CustomPropTypes.notAllowed,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: CustomPropTypes.notAllowed,
}

SvgBaseIcon.defaultProps = {
  className: '',
  size: 24,
  color: 'currentColor',
  xmlns: null,
  width: null,
  height: null,
  fill: null,
}

export default memo(SvgBaseIcon)
