import { memo } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import PhoneIcon from '@mui/icons-material/Phone'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { FormLabel } from 'subFramework'
import styles from './literalValue.module.scss'

const LiteralValue = ({ label, value, type }) => (
  <label className={c(styles.literalLabel)}>
    {label && <FormLabel label={label} viewMode />}
    <div className={styles.literalValue}>
      {value !== null && value !== '' ? value : '-'}
      {type === 'link' && value?.length > 0 && (
        <a className={styles.link} href={value} target="_blank" rel="noreferrer">
          <OpenInNewIcon />
        </a>
      )}
      {type === 'mail' && value?.length > 0 && (
        <a className={styles.email} href={`mailto:${value}`} target="_blank" rel="noreferrer">
          <MailOutlineIcon />
        </a>
      )}
      {type === 'phone' && value?.length > 0 && (
        <a className={styles.phone} href={`tel:${value}`} target="_blank" rel="noreferrer">
          <PhoneIcon />
        </a>
      )}
      {type === 'mobile' && value?.length > 0 && (
        <div>
          <a className={styles.mobile} href={`tel:${value}`} target="_blank" rel="noreferrer">
            <PhoneAndroidIcon />
          </a>
          <a
            className={styles.mobile}
            href={`https://wa.me/${value}`}
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon />
          </a>
        </div>
      )}
    </div>
  </label>
)

LiteralValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
}

LiteralValue.defaultProps = {
  label: null,
  value: null,
  type: null,
}

export default memo(LiteralValue)
