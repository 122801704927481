import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const CloseIcon = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 12 12">
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47269 6L0 0.527307L0.527307 0L6 5.47269L11.4727 0L12 0.527307L6.52731 6L12 11.4727L11.4727 12L6 6.52731L0.527307 12L0 11.4727L5.47269 6Z"
    />
  </SvgBaseIcon>
)

CloseIcon.propTypes = {
  children: PropTypes.elementType,
}

CloseIcon.defaultProps = {
  children: null,
}

export default memo(CloseIcon)
