import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ToastError, ToastInfo, ToastSuccess } from 'presentation/Toast'
import { MESSAGE_TYPE } from './constants'

const ToastMap = {
  [MESSAGE_TYPE.INFO]: ToastInfo,
  [MESSAGE_TYPE.SUCCESS]: ToastSuccess,
  [MESSAGE_TYPE.ERROR]: ToastError,
}

function useToast({ type = MESSAGE_TYPE.INFO, staticTitle, staticMessage }) {
  const Component = ToastMap[type]
  const { t } = useTranslation('Toast')

  return useCallback(
    (title = staticTitle, message = staticMessage) => {
      toast(
        <Component
          title={title ?? t(`${type.toLowerCase()}.defaultTitle`)}
          message={message ?? t(`${type.toLowerCase()}.defaultMessage`)}
        />
      )
    },
    [Component, t]
  )
}

useToast.propTypes = {
  type: PropTypes.oneOf([MESSAGE_TYPE.INFO, MESSAGE_TYPE.ERROR, MESSAGE_TYPE.SUCCESS]).isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
}

useToast.defaultProps = {
  type: MESSAGE_TYPE.INFO,
  title: null,
  message: null,
}

export default useToast
