import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const RoundedCheckIcon = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 34 34">
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 2.21369C15.0582 2.21369 13.1355 2.59615 11.3415 3.33923C9.54757 4.08231 7.91754 5.17146 6.5445 6.5445C5.17146 7.91754 4.08231 9.54757 3.33923 11.3415C2.59615 13.1355 2.21369 15.0582 2.21369 17C2.21369 18.9418 2.59615 20.8645 3.33923 22.6585C4.08231 24.4524 5.17146 26.0825 6.5445 27.4555C7.91754 28.8285 9.54757 29.9177 11.3415 30.6608C13.1355 31.4039 15.0582 31.7863 17 31.7863C20.9216 31.7863 24.6825 30.2285 27.4555 27.4555C30.2285 24.6825 31.7863 20.9216 31.7863 17C31.7863 13.0784 30.2285 9.31747 27.4555 6.5445C24.6825 3.77153 20.9216 2.21369 17 2.21369ZM10.4944 1.29405C12.5569 0.439718 14.7675 0 17 0C21.5087 1.01497e-07 25.8327 1.79107 29.0208 4.97919C32.2089 8.1673 34 12.4913 34 17C34 21.5087 32.2089 25.8327 29.0208 29.0208C25.8327 32.2089 21.5087 34 17 34C14.7675 34 12.5569 33.5603 10.4944 32.7059C8.43185 31.8516 6.55778 30.5994 4.97918 29.0208C3.40059 27.4422 2.14838 25.5682 1.29405 23.5056C0.439718 21.4431 0 19.2325 0 17C0 14.7675 0.439718 12.5569 1.29405 10.4944C2.14838 8.43185 3.40059 6.55778 4.97918 4.97918C6.55778 3.40059 8.43185 2.14838 10.4944 1.29405ZM25.8109 12.0153L16.0237 24.2493L8.34484 17.8503L9.76201 16.1497L15.7059 21.1029L24.0823 10.6324L25.8109 12.0153Z"
    />
  </SvgBaseIcon>
)

RoundedCheckIcon.propTypes = {
  children: PropTypes.elementType,
}

RoundedCheckIcon.defaultProps = {
  children: null,
}

export default memo(RoundedCheckIcon)
