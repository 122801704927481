import axios from 'axios'
import Cookies from 'js-cookie'

class Permissions {
  /* eslint-disable class-methods-use-this */
  getPermissionsFromCookies() {
    return JSON.parse(Cookies.get('authUser'))?.permissions
  }

  getPermissionsList() {
    const permissions = this.getPermissionsFromCookies()

    if (!permissions) {
      return this.getPermissionsFromAPI()
    }

    return Promise.resolve({
      data: permissions,
    })
  }

  getPermissionsFromAPI = () => axios.get('/users/permissions')
}

export default Permissions
