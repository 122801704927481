import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import c from 'classnames'
import { NavLink } from 'react-router-dom'
import { parsePath } from 'util/location'
import CaretDownIcon from 'assets/icons/CaretDownIcon'
import styles from './menu.module.scss'

const Menu = ({ menu, onChange, vertical, onClickExpand, onlyIcons }) => (
  <nav className={c(styles.menu, vertical && styles.vertical, onlyIcons && styles.onlyIcons)}>
    {menu.length > 0 &&
      menu.map(
        ({
          id,
          icon: MenuIcon,
          display,
          group,
          routePath = '',
          exactQuery,
          subMenu,
          state,
          ...props
        }) =>
          subMenu != null ? (
            <div key={uuid()}>
              <NavLink
                className={styles.menu__item}
                {...props}
                activeClassName={styles['menu__item--selected']}
                id={id}
                key={uuid()}
                onClick={(e) => onChange(id, e)}
                to={{ ...parsePath(routePath), state: { ...state } }}
                data-navtype="Menu"
              >
                {MenuIcon != null && <MenuIcon className={styles.menu__icon} />}
                <div className={styles.menu__text}>{display}</div>
                {subMenu.length > 0 && (
                  <CaretDownIcon className={styles.menu__extend} onClick={onClickExpand} />
                )}
              </NavLink>
              {subMenu.length > 0 &&
                subMenu.map(
                  ({
                    id: subMenuId,
                    icon: SubMenuIcon,
                    display: subMenuDisplay,
                    routePath: subMenuRoutePath = '',
                    selected,
                    exactQuery: subMenuExactQuery,
                    state: subMenuState,
                    ...subMenuProps
                  }) => (
                    <NavLink
                      className={c(styles.menu__item, styles.subMenu__item)}
                      activeClassName={styles['submenu__item--selected']}
                      {...subMenuProps}
                      id={subMenuId}
                      key={uuid()}
                      onClick={(e) => onChange(id, e)}
                      to={{
                        ...parsePath(subMenuRoutePath),
                        state: { ...subMenuState },
                      }}
                      data-navtype="SubMenu"
                    >
                      {SubMenuIcon != null && <SubMenuIcon className={styles.menu__icon} />}
                      <div className={styles.menu__text}>{subMenuDisplay}</div>
                    </NavLink>
                  )
                )}
            </div>
          ) : (
            <NavLink
              className={styles.menu__item}
              {...props}
              activeClassName={styles['menu__item--selected']}
              id={id}
              key={uuid()}
              onClick={(e) => onChange(id, e)}
              to={{ ...parsePath(routePath), state: { menu: display } }}
            >
              {MenuIcon != null && <MenuIcon className={styles.menu__icon} />}
              <div className={styles.menu__text}>{display}</div>
            </NavLink>
          )
      )}
  </nav>
)

Menu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({}),
      display: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      routePath: PropTypes.string,
      group: PropTypes.string,
      items: PropTypes.shape({
        id: PropTypes.string,
        showSubMenu: PropTypes.bool,
      }),
      isActive: PropTypes.func,
    })
  ),
  onChange: PropTypes.func,
  onClickExpand: PropTypes.func,
  vertical: PropTypes.bool,
  onlyIcons: PropTypes.bool,
}

Menu.defaultProps = {
  menu: [],
  onChange: () => {},
  onClickExpand: () => {},
  vertical: true,
  onlyIcons: false,
}

export default Menu
