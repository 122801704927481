import Model from 'models/Model'

class Link extends Model {
  constructor(url, name, ...props) {
    super(...props)

    this.url = url
    this.name = name
  }

  static create({ url, name, id, createDate, updateDate }) {
    return new Link(url, name, id, createDate, updateDate)
  }

  toString() {
    return this.url
  }

  valueOf() {
    const { url, name } = this
    return { ...super.valueOf(), url, name }
  }
}

export default Link
