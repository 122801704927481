import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const StatisticsIcon = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 40 40">
    {children}
    <path d="M14 26.2h2v-8.5h-2v8.5Zm5 0h2V13.8h-2v12.4Zm5 0h2v-4.6h-2v4.6ZM11 31c-.6 0-1-.2-1.4-.6-.4-.4-.6-.8-.6-1.4V11c0-.6.2-1 .6-1.4.4-.4.8-.6 1.4-.6h18c.6 0 1 .2 1.4.6.4.4.6.8.6 1.4v18c0 .6-.2 1-.6 1.4-.4.4-.8.6-1.4.6H11Zm0-2h18V11H11v18Z" />
  </SvgBaseIcon>
)

StatisticsIcon.propTypes = {
  children: PropTypes.elementType,
}

StatisticsIcon.defaultProps = {
  children: null,
}

export default memo(StatisticsIcon)
