import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { HOME_PATH } from 'routing/Routes'
import c from 'classnames'
import { useRootStore } from 'providers/RootProvider'
import Header from 'presentation/Header'
import Footer from 'presentation/Footer'
import Menu from 'presentation/Menu'
import Logotype from 'presentation/Logotype'
import UserDataPlate from 'presentation/UserDataPlate'
import LogoutButton from 'presentation/LogoutButton'
import CaretDownIcon from 'assets/icons/CaretDownIcon'

import pluginMenu from 'plugins/Menu'
import pluginRoutes from 'plugins/Routes'

import ProfileScene from 'features/ProfileScene'
import ApplicantFeatureScene from 'features/ApplicantScene'
import RecruitingProcessScene from 'features/RecruitingProcessScene'
import StatisticsScene from 'features/StatisticsScene'
import ComparativeScene from 'features/ComparativeScene'
import AdminPanelScene from 'features/AdminPanelScene'

import MainFormStore from './MainFormStore'
import styles from './mainScene.module.scss'

const MainScene = ({ children }) => {
  const location = useLocation()
  const { plugInStore, footerStore, permissionStore } = useRootStore()
  const [mainFormStore] = useState(new MainFormStore())

  const { canView: canViewAdminPanel } = permissionStore.getPermissions('adminPanel')

  const { state } = location
  const { menu: menuName, subMenu: subMenuName } = state ?? {}

  const {
    aside: { colapsed: asideColapsed },
  } = mainFormStore

  const { menu } = plugInStore

  const profileFeature = plugInStore.getFeatureProps('profile')

  const { left, middle, right } = footerStore

  useEffect(() => {
    plugInStore.use(pluginMenu)
    plugInStore.use(pluginRoutes)

    return () => plugInStore.clearAll()
  }, [])

  useEffect(() => {
    if (plugInStore != null) {
      plugInStore.registerFeature(ProfileScene)
      plugInStore.registerFeature(ApplicantFeatureScene)
      plugInStore.registerFeature(RecruitingProcessScene)
      plugInStore.registerFeature(StatisticsScene)
      plugInStore.registerFeature(ComparativeScene)
    }
  }, [plugInStore])

  useEffect(() => {
    if (plugInStore != null && canViewAdminPanel) {
      plugInStore.registerFeature(AdminPanelScene)
    }
  }, [plugInStore, canViewAdminPanel])

  const handlerClickExpand = (e) => {
    e.preventDefault()
    const { target } = e
    const menuItem = target.parentElement
    const dataToggle = menuItem.getAttribute('data-toggle') === 'true'
    menuItem.setAttribute('data-toggle', !dataToggle)
  }

  const handleNavControlClick = () => {
    mainFormStore.toggleAsideColapsed()
  }

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <aside className={c(styles.nav, asideColapsed && styles['nav--colapsed'])}>
          <div className={styles.nav__top}>
            <NavLink to={HOME_PATH} className={styles.logo}>
              {asideColapsed ? (
                <Logotype wide showIso height="40px" />
              ) : (
                <Logotype wide height="40px" />
              )}
            </NavLink>
            {menu?.length > 0 && (
              <Menu
                menu={menu}
                vertical
                onClickExpand={handlerClickExpand}
                onlyIcons={asideColapsed}
              />
            )}
          </div>
          <div className={styles.nav__bottom}>
            <LogoutButton onlyIcons={asideColapsed} />
          </div>
          <div
            className={styles.nav__control}
            onClick={handleNavControlClick}
            role="button"
            tabIndex={0}
          >
            <CaretDownIcon
              className={c(
                styles.control__icon,
                asideColapsed && styles['control__icon--colapsed']
              )}
            />
          </div>
        </aside>
        <div className={styles.wrapper}>
          <Header
            leftSide={
              <div className={styles['header--left']}>
                <span className={styles.header__menu}>{menuName}</span>
                {subMenuName != null && (
                  <span className={styles.header__submenu}> - {subMenuName}</span>
                )}
              </div>
            }
            rightSide={
              <div className={styles['header--right']}>
                <UserDataPlate to={profileFeature?.sceneRoute} />
              </div>
            }
          />
          <div className={styles.content}>{children}</div>
          {(footerStore.length > 0 || middle.length > 0 || right.length > 0) && (
            <Footer leftSide={left} rightSide={right}>
              {middle}
            </Footer>
          )}
        </div>
      </div>
    </div>
  )
}

MainScene.propTypes = {
  children: PropTypes.node,
}

MainScene.defaultProps = {
  children: null,
}

export default observer(MainScene)
