import { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { observer } from 'mobx-react'

import LoadingRing from 'subFramework/presentation/LoadingRing'
import FeatureRouter from './FeatureRouter'

const Router = () => (
  <Suspense fallback={<LoadingRing center />}>
    <Switch>
      <FeatureRouter />
    </Switch>
  </Suspense>
)

export default observer(Router)
