import Model from 'models/Model'

class IdentityDocument extends Model {
  constructor(type, expeditedBy, ...props) {
    super(...props)
    this.type = type
    this.expeditedBy = expeditedBy
  }

  static create({ type, expeditedBy, id, createDate, updateDate }) {
    return new IdentityDocument(type, expeditedBy, id, createDate, updateDate)
  }

  valueOf() {
    const { type, expeditedBy } = this
    return { ...super.valueOg(), type, expeditedBy }
  }
}

export default IdentityDocument
