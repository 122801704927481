import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const ApplicantIcon = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 40 40">
    {children}
    <path d="M20 22.7c.7 0 1.4-.3 2-.8.4-.5.7-1.2.7-1.9s-.3-1.4-.8-2c-.5-.4-1.2-.7-1.9-.7s-1.4.3-2 .8c-.4.5-.7 1.2-.7 1.9s.3 1.4.8 2c.5.4 1.2.7 1.9.7Zm-5.5 5.5h11v-.7c0-.5-.1-1-.4-1.5a3 3 0 0 0-1.2-1 13 13 0 0 0-1.9-.6 9.2 9.2 0 0 0-4 0l-1.9.6a2.7 2.7 0 0 0-1.6 2.5v.7Zm14.3 5.6H11.2c-.6 0-1-.2-1.5-.7a2 2 0 0 1-.7-1.5V8.4c0-.6.2-1 .7-1.5a2 2 0 0 1 1.5-.7h11.6l8.2 8.3v17c0 .7-.2 1.2-.7 1.6a2 2 0 0 1-1.5.7Zm0-2.2V15.4l-7-7H11.2v23.2h17.6Z" />
  </SvgBaseIcon>
)

ApplicantIcon.propTypes = {
  children: PropTypes.elementType,
}

ApplicantIcon.defaultProps = {
  children: null,
}

export default memo(ApplicantIcon)
