import { observable, action, makeObservable } from 'mobx'

class PlugInStore {
  #middleware = []

  features = []

  constructor() {
    makeObservable(this, {
      // observables
      features: observable,
      // actions
      registerFeature: action,
      clearAll: action,
      use: action,
    })
  }

  getFeatureProps(featureId) {
    return this.features.find(({ id }) => id === featureId)
  }

  featureExist(featureId) {
    return this.features.some(({ id }) => id === featureId)
  }

  clearAll() {
    this.features = []

    this.#middleware.forEach((middleware) => {
      const clearProps = middleware.call(this, {})
      clearProps()
    })
  }

  use(middleware) {
    if (!(middleware instanceof Function)) {
      throw new TypeError('parameter is not a function')
    }

    this.#middleware.push(middleware)
  }

  registerFeature({ id, ...props }) {
    if (id == null) {
      throw new TypeError('id is not defined')
    }

    if (this.featureExist(id)) {
      return
    }

    this.#middleware.forEach((middleware) => middleware.call(this, { id, ...props }))

    this.features.push({ id, ...props })
  }
}

export default PlugInStore
