import { observable, makeObservable } from 'mobx'
import SubFrameWorkRootStore from 'subFramework/stores/RootStore'
import PermissionStore from 'stores/PermissionStore'
import PlugInStore from 'stores/PlugInStore'
import FooterStore from 'stores/FooterStore'

class RootStore extends SubFrameWorkRootStore {
  plugInStore = new PlugInStore()
  footerStore = new FooterStore()
  permissionStore = new PermissionStore()

  constructor(...params) {
    super(...params)

    makeObservable(this, {
      // observables
      plugInStore: observable,
      footerStore: observable,
      permissionStore: observable,
    })
  }
}

export default RootStore
