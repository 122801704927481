import axios from 'axios'
import Cookies from 'js-cookie'
import AuthUser from '../models/AuthUser'

axios.defaults.baseURL = process.env.REACT_APP_API_URLBASE

class AuthService {
  /** returns an AuthUser, this function should be implemented by the children class to customize AuthUser data * */
  getAuthUserFromJson = (data) => AuthUser.fromJson(data)

  getAuthUserFromCookie = (data) => AuthUser.fromCookie(data)

  /** sets whatever is needed for loadAuthUserFromBrowser to work * */
  persistLoginData = (authUser) => {
    Cookies.set('authUser', JSON.stringify(authUser))
  }

  /** sets whatever is needed for loadAuthUserFromBrowser to work * */
  getStoredLoginData = () => Cookies.get('authUser')

  /** removes whatever was setted in persistLoginData * */
  removePersistedData = () => {
    Cookies.remove('authUser')
  }

  /** should return the athentication api endpoint url * */
  getAuthenticateUri = () => '/authentication/authenticate'

  /** should return the logout api endpoint url * */
  getLogoutUri = () => '/authentication/logout'

  authenticate = (email, password) =>
    axios
      .post(this.getAuthenticateUri(), {
        email,
        password,
      })
      .then((data) => {
        const { user, accessToken } = data.data
        user.token = accessToken

        const authUser = this.getAuthUserFromJson(user)

        this.persistLoginData(authUser)

        return authUser
      })

  logout = async () => {
    await axios.post(this.getLogoutUri())

    this.removePersistedData()

    return true
  }

  loadAuthUserFromBrowser = () => {
    const authUser = this.getStoredLoginData()

    if (authUser) {
      return this.getAuthUserFromCookie(JSON.parse(authUser))
    }

    return null
  }

  recoverPassword = (email) => axios.post('/authentication/reset', { email })

  editProfile = (payload) => axios.post('users/change-password', payload)
}

export default AuthService
