import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './error404.module.scss'

function Error404() {
  const { t } = useTranslation()

  return (
    <div className={styles.error__container}>
      <h1 className={styles.error__status}>404</h1>
      <h3 className={styles.error__description}>{t('pageNotFound')}</h3>
    </div>
  )
}

export default Error404
