import Model from 'models/Model'
import Applicant from 'models/Applicant'
import List from 'models/List'
import Event from './Event'

class RecruitingProcess extends Model {
  constructor(status, applicant, events, ...props) {
    super(...props)

    this.status = status
    this.applicant = Applicant.create(applicant)
    this.events = List.from(events, null, Event)
  }

  static create({ status, applicant, events }) {
    return new RecruitingProcess(status, applicant, events)
  }

  valueOf() {
    const { status, applicant, events } = this
    return { ...super.valueOf(), status, applicant, events }
  }
}

export default RecruitingProcess
