import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

import { Button } from 'subFramework'

import CloseIcon from 'assets/icons/CloseIcon'
import RoundedExclamationIcon from 'assets/icons/RoundedExclamationIcon'
import RoundedCrossIcon from 'assets/icons/RoundedCrossIcon'
import RoundedCheckIcon from 'assets/icons/RoundedCheckIcon'

import { MESSAGE_TYPE } from 'util/constants'
import styles from './frame.module.scss'

const iconsMap = {
  [MESSAGE_TYPE.INFO]: RoundedExclamationIcon,
  [MESSAGE_TYPE.ERROR]: RoundedCrossIcon,
  [MESSAGE_TYPE.SUCCESS]: RoundedCheckIcon,
}

function Frame({
  type,
  children,
  onClose,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  title,
  titleIcon,
  isLoading,
}) {
  const Icon = titleIcon || iconsMap[type]

  return (
    <div className={styles.frame__container}>
      <div
        className={c(
          styles.frame__content,
          type === MESSAGE_TYPE.SUCCESS
            ? styles.frame__content_success
            : styles.frame__content_normal
        )}
      >
        <div className={styles.frame__header}>
          <Icon className={styles.frame__header_icon} />
          <div className={styles.frame__header_title}>{title}</div>
          <CloseIcon onClick={onClose} className={styles.frame__header_close} />
        </div>
        <div className={styles.frame__body}>{children}</div>
        <div className={styles.frame__footer}>
          {onCancel && (
            <Button
              disabled={isLoading}
              big
              cancel
              label={cancelLabel}
              onClick={onCancel}
              as="button"
            />
          )}
          {onConfirm && (
            <Button disabled={isLoading} big label={confirmLabel} onClick={onConfirm} as="button" />
          )}
        </div>
      </div>
    </div>
  )
}

Frame.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.node,
  isLoading: PropTypes.bool,
}

Frame.defaultProps = {
  type: MESSAGE_TYPE.INFO,
  children: null,
  onClose: () => {},
  onConfirm: null,
  onCancel: null,
  confirmLabel: 'Save',
  cancelLabel: 'Cancel',
  titleIcon: null,
  isLoading: false,
}

export default Frame
