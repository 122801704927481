import { memo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import Input from 'subFramework/presentation/Input'
import InputStore from 'subFramework/stores/InputStore'
import PropTypes from 'prop-types'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import styles from './inputWrapper.module.scss'

const InputWrapper = ({
  wrapperClassName,
  className,
  translationFile,
  as: Component,
  placeholder,
  description,
  inputStore,
  alignError,
  inputRef,
  required,
  literal,
  label,
  name,
  Icon,
  onClickIcon,
  ...props
}) => {
  const { t } = useTranslation(translationFile)
  let useLabel = label
  let usePlaceholder = placeholder

  if (name !== null) {
    if (label === null) {
      useLabel = t(`${name}Label`)
    }

    if (placeholder === null) {
      usePlaceholder = t(`${name}Placeholder`)
    }
  }

  if (required && !literal) {
    useLabel = `${useLabel} *`
  }

  return (
    <div className={c(styles.wrapper, wrapperClassName && wrapperClassName, Icon && styles.icon)}>
      <Component
        className={c(className, styles.component)}
        value={inputStore && inputStore.value}
        error={inputStore && inputStore.error}
        placeholder={usePlaceholder}
        inputRef={inputRef}
        literal={literal}
        label={useLabel}
        {...props}
      />
      {Icon && <Icon onClick={onClickIcon} className={styles.input_icon} />}
      {inputStore && inputStore.error && inputStore.errorMessage !== '' && (
        <div className={styles.error_container}>
          <ErrorOutlineIcon className={c(styles.error, styles.errorIcon)} />
          <small
            className={c(
              styles.error,
              literal && styles.errorLiteral,
              styles[`align-${alignError}`]
            )}
          >
            {inputStore.errorMessage}
          </small>
        </div>
      )}
      {description && <span className={styles.description}>{description}</span>}
    </div>
  )
}

InputWrapper.propTypes = {
  as: PropTypes.elementType,
  alignError: PropTypes.oneOf(['start', 'center', 'end']),
  inputStore: PropTypes.instanceOf(InputStore),
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  translationFile: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  required: PropTypes.bool,
  label: PropTypes.string,
  literal: PropTypes.bool,
  name: PropTypes.string,
  Icon: PropTypes.elementType,
  onClickIcon: PropTypes.func,
}

InputWrapper.defaultProps = {
  wrapperClassName: null,
  className: null,
  translationFile: '',
  alignError: 'start',
  placeholder: null,
  description: null,
  inputStore: null,
  required: false,
  literal: false,
  inputRef: null,
  label: null,
  name: null,
  as: Input,
  Icon: null,
  onClickIcon: null,
}

export default memo(observer(InputWrapper))
