import { observable, action, makeObservable } from 'mobx'

class FooterStore {
  constructor() {
    this.left = []
    this.middle = []
    this.right = []

    makeObservable(this, {
      // observables
      left: observable,
      middle: observable,
      right: observable,

      // actions
      add: action,
      addLeft: action,
      addMiddle: action,
      addRight: action,
      clearLeft: action,
      clearMiddle: action,
      clearRight: action,
      clearAll: action,
    })
  }

  add({ left, middle, right }) {
    if (left != null) {
      this.addLeft(left)
    }

    if (middle != null) {
      this.addMiddle(middle)
    }

    if (right != null) {
      this.addRight(right)
    }
  }

  addLeft(element) {
    this.left.push(element)
  }

  addMiddle(element) {
    this.middle.push(element)
  }

  addRight(element) {
    this.right.push(element)
  }

  clearLeft() {
    this.left = []
  }

  clearMiddle() {
    this.middle = []
  }

  clearRight() {
    this.right = []
  }

  clearAll() {
    this.clearLeft()
    this.clearMiddle()
    this.clearRight()
  }
}

export default FooterStore
