import { observable, action, override, makeObservable } from 'mobx'
import { AsyncStore } from 'subFramework'

export default class InputStore extends AsyncStore {
  error = false
  value = ''
  errorMessage = ''

  constructor(value) {
    super()

    this.value = value

    makeObservable(this, {
      // observables
      error: observable,
      value: observable,
      errorMessage: observable,
      // actions
      setError: action,
      setValue: action,
      clearAll: action,
      // override actions
      clearError: override,
    })
  }

  setError(errorMessage) {
    this.error = true
    this.errorMessage = errorMessage
  }

  setValue(value) {
    this.value = value || ''
    this.clearError()
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
  }

  clearAll() {
    this.error = false
    this.errorMessage = ''
    this.value = ''
  }
}
