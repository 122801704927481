import moment from 'moment'
import Model from 'models/Model'
import List from 'models/List'
import Contact from 'models/Contact'
import IdentityDocument from 'models/IdentityDocument'

class Person extends Model {
  constructor(firstName, lastName, fullName, identityDocuments, birthDate, contact, ...props) {
    super(...props)

    const [parsedLastName, ...parsedName] = fullName?.split(' ').reverse() ?? [] // NOTE: No es exacto pero sirve por ahora

    this.firstName = firstName ?? parsedName
    this.lastName = lastName ?? parsedLastName
    this.identityDocuments = List.from(identityDocuments, null, IdentityDocument)
    this.birthDate = birthDate
    this.contact = List.from(contact, null, Contact)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get reverseFullName() {
    return `${this.lastName}, ${this.firstName}`
  }

  get age() {
    const momentBirthDate = moment(this?.birthDate)
    const age = moment().diff(momentBirthDate, 'years')
    return age
  }

  static create({
    firstName,
    lastName,
    fullName,
    identityDocuments,
    birthDate,
    contact,
    id,
    createDate,
    updateDate,
  }) {
    return new Person(
      firstName,
      lastName,
      fullName,
      identityDocuments,
      birthDate,
      contact,
      id,
      createDate,
      updateDate
    )
  }

  toString() {
    return this.fullName
  }

  valueOf() {
    const { firstName, lastName, identityDocuments, birthDate, contact } = this
    return {
      ...super.valueOf(),
      firstName,
      lastName,
      identityDocuments: identityDocuments.valueOf(),
      birthDate,
      contact: contact.valueOf(),
    }
  }

  get email() {
    return this.contact.find(Contact.isEmail)
  }

  get linkedin() {
    return this.contact.find(Contact.isLinkedin)
  }

  get phone() {
    return this.contact.find(Contact.isLandline)
  }

  get mobile() {
    return this.contact.find(Contact.isMobilePhone)
  }
}

export default Person
