import { observable, action, makeObservable } from 'mobx'
import { ROLE } from 'models/RecruitingProcess'

class AuthUser {
  constructor(userId, email, token, firstName, lastName, permissions, roles) {
    this.userId = userId
    this.token = token
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.permissions = permissions
    this.roles = roles

    makeObservable(this, {
      // Observers
      userId: observable,
      token: observable,
      email: observable,
      firstName: observable,
      lastName: observable,
      permissions: observable,
      roles: observable,
      // Actions
      updateToken: action,
      setPermissions: action,
      addPermission: action,
    })
  }

  updateToken(token) {
    this.token = token
  }

  setPermissions(permissions) {
    this.permissions = permissions
  }

  addPermission(permission) {
    this.permissions.push(permission)
  }

  static fromJson({ id, email, token, firstName, lastName, permissions, roles }) {
    return new AuthUser(id, email, token, firstName, lastName, permissions, roles)
  }

  static fromCookie({ userId, email, token, firstName, lastName, permissions, roles }) {
    return new AuthUser(userId, email, token, firstName, lastName, permissions, roles)
  }

  get isAdmin() {
    return this.roles.includes(ROLE.SUPER_ADMIN)
  }
}

export default AuthUser
