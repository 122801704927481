import React from 'react'
import MainScene from 'scenes/MainScene'
import Router from './Router'

function AuthorizedRouter() {
  return (
    <MainScene>
      <Router />
    </MainScene>
  )
}

export default AuthorizedRouter
