import { memo } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { LiteralValue, FormLabel } from 'subFramework'
import styles from './formSelect.module.scss'

const customStyles = (mergeStyles, error) => ({
  control: (provided, state) => {
    let borderColor = 'var(--form-select--border-color)'
    let outline = 'none'

    if (state.selectProps.readOnly || state.selectProps.disabled) {
      borderColor = 'var(--form-select-disabled--border-color)'
    } else {
      if (state.isFocused) {
        outline = '2px solid var(--open-menu-color)'
      }

      if (error) {
        borderColor = 'var(--error-color)'
      }
    }

    return {
      ...provided,
      opacity: state.isDisabled ? '0.3' : '1',
      borderColor,
      outline,
      borderRadius: '8px',
      boxShadow: 'none',
      fontFamily: 'var(--main-font-family)',
      fontWeight: '400',
      fontSize: 'var(--form-select-text-size)',
      fontStyle: 'normal',
      color: 'var(--form-select-text-color)',
      height: '50px',
      minHeight: '40px',
      padding: '0 16px',
      width: '100%',
      ':hover': {
        borderColor,
      },
      backgroundColor: 'var(--form-select-background)',
      letterSpacing: '0.3px',
      ...mergeStyles.control,
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--form-select--placeholder-color)',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid var(--gray_2--color)',
    margin: 0,
    overflow: 'hidden',
    width: '100%',
    zIndex: 13,
    backgroundColor: 'var(--form-menu-background)',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '250px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 'var(--form-select-text-size)',
    padding: '12px 16px',
    ':active': {
      color: 'var(--form-select-hover-option-color)',
    },
    color: state.isSelected
      ? 'var(--form-select-selected-option-color)'
      : 'var(--form-select-text-color)',
    backgroundColor: state.isFocused
      ? 'var(--form-select-default-option-hover-background)'
      : 'var(--form-select-default-option-background)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--form-select-text-color)',
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'var(--form-select-multivalue-option-background)',
    borderRadius: '100px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--form-select-multivalue-option-color)',
    height: '24px',
    padding: '0',
    paddingLeft: '10px',
    lineHeight: '24px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'var(--form-select-multivalue-option-color)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--primary-color)',
    margin: 0,
    padding: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color:
      state.selectProps.readOnly || state.selectProps.disabled
        ? 'var(--form-select-disabled--icon-color)'
        : 'var(--form-select--icon-color) !important',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color:
      state.selectProps.readOnly || state.selectProps.disabled
        ? 'var(--form-select-disabled--icon-color)'
        : 'var(--form-select--icon-color) !important',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    span: {
      backgroundColor: 'black',
    },
  }),
})

const FormSelect = ({
  formSelectAs: Component,
  inputRef,
  label,
  mergeStyles,
  disabled,
  readOnly,
  error,
  literal,
  value,
  isMulti,
  options,
  labelClassName,
  ...props
}) =>
  literal ? (
    <LiteralValue
      label={label}
      value={isMulti ? value.map((e) => e.value).join(', ') : (value && value.value) || '-'}
    />
  ) : (
    <label className={styles.label}>
      {label && (
        <FormLabel label={label} className={labelClassName} disabled={disabled || readOnly} />
      )}
      <Component
        options={options}
        styles={customStyles(mergeStyles, error)}
        ref={inputRef}
        isDisabled={disabled}
        isClearable={!readOnly}
        isSearchable={!readOnly}
        openMenuOnClick={!readOnly}
        menuIsOpen={readOnly ? false : undefined}
        readOnly={readOnly}
        getOptionLabel={(option) => {
          // eslint-disable-next-line no-underscore-dangle
          if (option.__isNew__) {
            return option.label
          }

          return option.value
        }}
        getOptionValue={(option) => option.id}
        value={value}
        isMulti={isMulti}
        {...props}
      />
    </label>
  )

/* eslint-disable react/forbid-prop-types */
FormSelect.propTypes = {
  formSelectAs: PropTypes.elementType,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  inputRef: PropTypes.func,
  mergeStyles: PropTypes.oneOfType([PropTypes.any]),
  error: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  literal: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
    PropTypes.number,
  ]),
}

FormSelect.defaultProps = {
  label: '',
  labelClassName: '',
  formSelectAs: Select,
  inputRef: null,
  options: [],
  mergeStyles: { control: {} },
  disabled: false,
  readOnly: false,
  error: false,
  literal: false,
  isMulti: false,
  value: null,
}

export default memo(FormSelect)
