/* eslint-disable no-param-reassign */
import axios from 'axios'
import { LANG } from 'util/language'
import RequestError from 'models/RequestError/RequestError'

const axiosInterceptors = (authStore) => {
  // // Handles all 503 requests
  // axios.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error.response.status === 503) {
  //       authStore.setApiOffline()
  //     }
  //     return Promise.reject(error)
  //   }
  // )
  // // Handles all 403 requests
  // axios.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error.response !== undefined && error.response.status > 403) {
  //       error.response.unknown = true
  //     }
  //     return Promise.reject(error)
  //   }
  // )

  // Handles all 401 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 && authStore.isAuthenticated) {
        authStore.logout()
      }
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    ({ data }) => data,
    ({ response = {} }) => {
      const { data: { error = {}, status } = {} } = response

      return Promise.reject(new RequestError({ ...error, status }))
    }
  )

  // Adds bearer to all API requests
  axios.interceptors.request.use(
    (config) => {
      if (authStore.isAuthenticated) {
        config.headers.Authorization = `Bearer ${
          authStore.authUser ? authStore.authUser.token : null
        }`
      }

      // Do something before request is sent
      return config
    },
    (error) => Promise.reject(error)
  )

  // Add language to headers
  axios.interceptors.request.use(
    (config) => ({ ...config, headers: { ...config.headers, 'Accept-Language': LANG } }),
    (error) => Promise.reject(error)
  )
}

export default axiosInterceptors
