import Model from 'models/Model'

class SocialNetworks extends Model {
  constructor(url, socialNetwork, ...props) {
    super(...props)

    this.url = url

    if (socialNetwork != null) {
      this.socialNetwork = socialNetwork
      return
    }

    if (url.match(/linkedin\.com/)) {
      this.socialNetwork = 'linkedin'
    }

    if (url.match(/facebook\.com/)) {
      this.socialNetwork = 'facebook'
    }

    if (url.match(/twitter\.com/)) {
      this.socialNetwork = 'twitter'
    }
  }

  static create({ url, socialNetwork, id, createDate, updateDate }) {
    return new SocialNetworks(url, socialNetwork, id, createDate, updateDate)
  }

  toString() {
    return this.url
  }

  valueOf() {
    const { url, socialNetwork } = this
    return { ...super.valueOf(), url, socialNetwork }
  }
}

export default SocialNetworks
