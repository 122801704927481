// ADMIN_PANEL
export const ADMIN_PANEL_VIEW_PERMISSION = 'adminPanel.view'
export const ADMIN_PANEL_CREATE_PERMISSION = 'adminPanel.create'
export const ADMIN_PANEL_EDIT_PERMISSION = 'adminPanel.edit'
export const ADMIN_PANEL_DELETE_PERMISSION = 'adminPanel.delete'

// STATISTICS
export const STATISTICS_VIEW_PERMISSION = 'statistics.view'

// COMPARATIVE
export const COMPARATIVE_VIEW_PERMISSION = 'process.view' // TODO: OJO!!! cambia a lo  que corresponda

// PROFILE
export const PROFILE_VIEW_PERMISSION = 'profile.view'
export const PROFILE_EDIT_PERMISSION = 'profile.edit'

// APLICANTS
export const APPLICANT_VIEW_PERMISSION = 'applicant.view'
export const APPLICANT_LIST_PERMISSION = 'applicant.list'
export const APPLICANT_EDIT_PERMISSION = 'applicant.edit'
export const APPLICANT_CREATE_PERMISSION = 'applicant.create'
export const APPLICANT_DELETE_PERMISSION = 'applicant.delete'

// PROCESS
export const PROCESS_VIEW_PERMISSION = 'process.view'
export const PROCESS_CANCEL_PERMISSION = 'process.cancel'
export const PROCESS_RESTART_PERMISSION = 'process.restart'

// EVALUATION
export const EVALUATION_VIEW_PERMISSION = 'evaluation.view'

// EVENTS
export const START_EVENT_EDIT_PERMISSION = 'event.start.edit'
export const CONTACT_EVENT_EDIT_PERMISSION = 'event.contact.edit'
export const CV_RECEIVED_EVENT_EDIT_PERMISSION = 'event.cvReceived.edit'
export const HR_INTERVIEW_EVENT_EDIT_PERMISSION = 'event.hrInterview.edit'
export const SPECIALIST_INTERVIEW_EVENT_EDIT_PERMISSION = 'event.specialistInterv.edit'
export const PSYCHO_TECHNICAL_EVENT_EDIT_PERMISSION = 'event.psychotechnical.edit'
export const OFFER_EVALUATION_EVENT_EDIT_PERMISSION = 'event.offerEvaluation.edit'
export const FORMAL_OFFER_EVENT_EDIT_PERMISSION = 'event.formalOffer.edit'
export const PREOCUPATIONAL_EVENT_EDIT_PERMISSION = 'event.preoccupational.edit'
export const BOOSTER_EVENT_EDIT_PERMISSION = 'event.booster.edit'
export const SCHEDULED_ENTRANCED_EVENT_EDIT_PERMISSION = 'event.scheduledEntranc.edit'
