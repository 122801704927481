import { useRootStore } from 'providers/RootProvider'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import styles from './userDataPlate.module.scss'

const UserDataPlate = ({ to, ...props }) => {
  const { authStore } = useRootStore()
  const { authUser } = authStore
  const { firstName, lastName } = authUser

  return (
    <Link className={styles.plate} to={to} {...props}>
      <AccountCircleIcon className={styles.plate__icon} />
      <div className={styles.plate__name}>
        <div className={styles.plate__firstname}>{firstName}</div>
        <div className={styles.plate__lastname}>{lastName}</div>
      </div>
    </Link>
  )
}

UserDataPlate.propTypes = {
  to: PropTypes.string,
}

UserDataPlate.defaultProps = {
  to: '',
}

export default UserDataPlate
