import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const SettingsIcons = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 40 40">
    {children}
    <path d="m17.9 29-.5-2.8a25.4 25.4 0 0 1-1.6-.9l-2.6 1.1-2.2-3.8 2.4-1.8v-.4a7.9 7.9 0 0 1 0-1.3L11 17.4l2.2-3.8 2.7 1.2a5.4 5.4 0 0 1 1.5-1L18 11h4l.5 2.9a5 5 0 0 1 1.5.9l2.7-1.2 2.2 3.8-2.4 1.7a7.2 7.2 0 0 1 0 1.8l2.4 1.7-2.2 3.8-2.7-1.2a5.3 5.3 0 0 1-1.5 1L22 29h-4Zm2-6a3 3 0 0 0 2.2-.9c.6-.6.8-1.3.8-2 0-1-.2-1.6-.8-2.2-.6-.6-1.3-.9-2.1-.9s-1.5.3-2.1 1c-.6.5-.9 1.2-.9 2s.3 1.5.9 2.1c.6.6 1.3.9 2 .9Zm0-1.5c-.3 0-.7-.1-1-.4-.3-.3-.5-.7-.5-1 0-.5.2-.9.5-1.2.3-.3.7-.4 1-.4.5 0 .9.1 1.2.4.3.3.4.7.4 1.1 0 .4-.1.8-.4 1.1-.3.3-.7.4-1.1.4Zm-.9 6h2l.3-2.5 1.3-.5a5 5 0 0 0 1.2-1l2.4 1.1.9-1.6-2.1-1.5a8.4 8.4 0 0 0 .2-1.5 5.5 5.5 0 0 0-.2-1.5l2-1.5-.8-1.6-2.4 1-1.2-.9a4 4 0 0 0-1.3-.5l-.3-2.5h-2l-.3 2.5-1.4.5a5 5 0 0 0-1.1 1l-2.4-1.1L13 17l2.1 1.5a10.5 10.5 0 0 0-.2 1.5v.7l.2.8-2 1.5.8 1.6 2.4-1a5.4 5.4 0 0 0 2.5 1.4l.3 2.5Z" />
  </SvgBaseIcon>
)

SettingsIcons.propTypes = {
  children: PropTypes.elementType,
}

SettingsIcons.defaultProps = {
  children: null,
}

export default memo(SettingsIcons)
