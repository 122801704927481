export const HOME_PATH = '/applicants'

/* Login */
export const LOGIN_PATH = '/login'
export const RECOVER_PASS_PATH = '/login/recover'

/* Applicants */
export const APPLICANT_LIST_PATH = '/applicants'
export const APPLICANT_ACTIVE_LIST_PATH = '/applicants?processStatus=in_progress'
export const APPLICANT_CANCELLED_LIST_PATH = '/applicants?processStatus=canceled'
export const APPLICANT_FINISHED_LIST_PATH = '/applicants?processStatus=finalized'
export const APPLICANT_DETAILS_PATH = '/applicants/detail/:id'
export const APPLICANT_CREATE_PATH = '/applicants/create'

/* Recruiting process */
export const RECRUITING_PROCESS_PATH = '/process/:id'
