import { observable, action, makeObservable } from 'mobx'

class MainFormStore {
  aside = {
    colapsed: false,
  }

  constructor() {
    makeObservable(this, {
      // observables
      aside: observable,
      // actions
      toggleAsideColapsed: action,
    })
  }

  toggleAsideColapsed() {
    this.aside.colapsed = !this.aside.colapsed
  }
}

export default MainFormStore
