import { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import es from 'date-fns/locale/es'
import { FormLabel, LiteralValue } from 'subFramework'
import c from 'classnames'
import styles from './datePickerInput.module.scss'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('es', es)

const DatePickerInput = forwardRef(
  (
    {
      placeholder,
      onChange,
      literal,
      value: paramValue,
      label,
      error,
      icon,
      dateFormat,
      literalValueFormat,
      disabled,
      readOnly,
      className,
      ...props
    },
    ref
  ) => {
    const value = moment(paramValue)

    if (literal) {
      return (
        <LiteralValue
          label={label}
          value={
            value != null && value !== '' && value.isValid()
              ? value?.format(literalValueFormat)
              : null
          }
        />
      )
    }

    return (
      <label onClick={(e) => e.preventDefault()} className={styles.datePickerLabel}>
        {label && <FormLabel label={label} disabled={disabled || readOnly} />}
        <div className={styles.pickerContainer}>
          <DatePicker
            locale="es"
            className={c(className, styles.dateInput, error && styles.error)}
            selected={value !== '' ? Date.parse(value) : null}
            onChange={(date) => onChange(date)}
            placeholderText={placeholder}
            dateFormat={dateFormat}
            disabled={disabled}
            readOnly={readOnly}
            ref={ref}
            {...props}
          />
          {icon && <span className={styles.pickerIcon}>{icon}</span>}
        </div>
      </label>
    )
  }
)

/* eslint-disable react/forbid-prop-types */
DatePickerInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.instanceOf(moment),
  ]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  literal: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.node,
  dateFormat: PropTypes.string,
  literalValueFormat: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
}

DatePickerInput.defaultProps = {
  literalValueFormat: 'MM/DD/YYYY',
  dateFormat: 'MM/dd/yyyy',
  onChange: () => {},
  placeholder: null,
  literal: false,
  error: false,
  value: null,
  label: null,
  icon: null,
  disabled: false,
  readOnly: false,
  className: null,
}

export default memo(DatePickerInput)
