import { observer } from 'mobx-react'
import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import RootStore from 'stores/RootStore'
import AuthStore from 'stores/AuthStore'
import axiosDefaults from 'util/axiosDefaults'
import axiosInterceptors from 'util/axiosInterceptors'

const RootStoreContext = createContext(null)

const RootStoreProvider = ({ children }) => {
  const [authStore] = useState(new AuthStore())
  const [rootStore] = useState(new RootStore(authStore))

  useEffect(() => {
    axiosDefaults(authStore)
    axiosInterceptors(authStore)
  }, [])

  if (authStore == null) {
    return null
  }

  return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
}

RootStoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

const useRootStore = () => useContext(RootStoreContext)

export default observer(RootStoreProvider)
export { useRootStore, RootStoreContext }
