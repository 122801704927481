export default function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function snakecaseToCamelcase(str) {
  return str.replace(/(_\w)/g, (k) => k[1].toUpperCase())
}

export const rangeOptions = ({ length, starts = 1, Type = Number }) =>
  Array.from({ length }, (x, i) => ({ id: Type(i + 1), value: Type(starts + i) }))

export const uniq = (dataArray) => [...new Set(dataArray)]
