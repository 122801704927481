import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './serverDomain.module.scss'

const ServerDomain = ({ domain, ...props }) => {
  if (domain == null) {
    return null
  }

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.serverDomain} {...props}>
        {`${t('Enviroment')} ${domain}`}
      </div>
    </div>
  )
}
ServerDomain.propTypes = {
  domain: PropTypes.string,
}

ServerDomain.defaultProps = {
  domain: null,
}

export default memo(ServerDomain)
