import Person from 'models/Person'
import Link from 'models/Link'
import List from 'models/List'
import { Event } from 'models/RecruitingProcess'

class Applicant extends Person {
  constructor(
    skills,
    currentJob,
    links,
    referrer,
    referrerName,
    other,
    profile,
    profileName,
    profileId,
    consulting,
    comments,
    rating,
    currentProcess,
    cv,
    imageUrl,
    gender,
    tags,
    processCompletion,
    lastEvent,
    processStatus,
    processCancel,
    shared,
    ...props
  ) {
    super(...props)

    this.skills = skills
    this.currentJob = currentJob
    this.links = List.from(links, null, Link)
    this.comments = comments
    this.rating = rating
    this.referrer = referrer
    this.referrerName = referrerName
    this.other = other
    this.profile = profile
    this.profileName = profileName
    this.profileId = profileId
    this.consulting = consulting
    this.currentProcess = currentProcess
    this.cv = cv
    this.imageUrl = imageUrl
    this.gender = gender
    this.tags = tags
    this.processCompletion = processCompletion
    this.lastEvent = Event.create(lastEvent)
    this.processStatus = processStatus
    this.processCompletion = processCompletion
    this.processCancel = processCancel
    this.sharedProfile = shared
  }

  static create({
    skills,
    currentJob,
    links,
    referrer,
    referrerName,
    other,
    profile,
    profileName,
    profileId,
    consulting,
    comments,
    rating,
    currentProcess,
    cv,
    imageUrl,
    gender,
    tags,
    processCompletion,
    lastEvent,
    processStatus,
    firstName,
    lastName,
    fullName,
    identityDocuments,
    birthDate,
    contact,
    id,
    createDate,
    updateDate,
    processCancel,
    sharedProfile,
  }) {
    return new Applicant(
      skills,
      currentJob,
      links,
      referrer,
      referrerName,
      other,
      profile,
      profileName,
      profileId,
      consulting,
      comments,
      rating,
      currentProcess,
      cv,
      imageUrl,
      gender,
      tags,
      processCompletion,
      lastEvent,
      processStatus,
      processCancel,
      sharedProfile,
      firstName,
      lastName,
      fullName,
      identityDocuments,
      birthDate,
      contact,
      id,
      createDate,
      updateDate
    )
  }

  valueOf() {
    const {
      skills,
      currentJob,
      links,
      referrer,
      referrerName,
      other,
      profile,
      profileName,
      profileId,
      consulting,
      comments,
      rating,
      currentProcess,
      cv,
      imageUrl,
      gender,
      tags,
      processCompletion,
      lastEvent,
      processStatus,
      processCancel,
      shared,
    } = this
    return {
      ...super.valueOf(),
      skills,
      currentJob,
      links: links.valueOf(),
      referrer,
      referrerName,
      other,
      profile,
      profileName,
      profileId,
      consulting,
      comments,
      rating,
      currentProcess,
      cv,
      imageUrl,
      gender,
      tags,
      processCompletion,
      lastEvent,
      processStatus,
      processCancel,
      shared,
    }
  }

  get contacts() {
    return {
      email: this.email?.toString(),
      linkedin: this.linkedin?.toString(),
      phone: this.phone?.toString(),
      mobile: this.mobile?.toString(),
      whatsappLink: this.mobile?.toWhatsappLink(),
    }
  }
}

export default Applicant
