class Model {
  constructor(id, createDate, updateDate) {
    this.id = id
    this.createDate = createDate
    this.updateDate = updateDate
  }

  static create({ id, createDate, updateDate }) {
    return new Model(id, createDate, updateDate)
  }

  toString() {
    return String(this.id)
  }

  valueOf() {
    const { id, createDate, updateDate } = this
    return { id, createDate, updateDate }
  }
}

export default Model
