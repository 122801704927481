import i18next from 'i18next'
import { observable, makeObservable, action } from 'mobx'
import { AsyncStore } from 'subFramework'
import InputStore from 'subFramework/stores/InputStore'

class LoginFormStore extends AsyncStore {
  constructor(authStore) {
    super()

    this.email = new InputStore()
    this.password = new InputStore()
    this.rememberMe = false
    this.isLoading = false
    this.authStore = authStore

    makeObservable(this, {
      // observables
      email: observable,
      password: observable,
      rememberMe: observable,

      // actions
      clearErrors: action,
    })
  }

  clearErrors() {
    this.errors = []
  }

  validate() {
    let isValid = true

    if (!this.validateUsername()) {
      isValid = false
    }

    if (!this.validatePassword()) {
      isValid = false
    }

    if (!isValid) {
      this.onErrorRequest(i18next.t('login:completeRequiredFields'))
    }

    return isValid
  }

  validateUsername() {
    return this.email.value.trim() !== ''
  }

  validatePassword() {
    return this.password.value.trim() !== ''
  }

  signIn(onError) {
    this.clearErrors()

    if (this.validate()) {
      this.preRequest()

      this.authStore
        .basicLogin(this.email.value, this.password.value, this.rememberMe.value)
        .catch(({ isValidationError, fields, message }) => {
          if (isValidationError) {
            const fieldNames = Object.keys(fields)

            fieldNames.forEach((fieldName) => this[fieldName].setError(fields[fieldName][0]))
          } else {
            onError(null, message)
          }
        })
        .finally(() => {
          this.onSuccessRequest()
        })
    }
  }
}

export default LoginFormStore
