import PropTypes from 'prop-types'
import styles from './header.module.scss'

const Header = ({ children, leftSide, rightSide, ...props }) => (
  <header className={styles.wrapper} {...props}>
    <section className={styles['header__left-container']}>{leftSide}</section>
    <section className={styles['header__middle-container']}>{children}</section>
    <section className={styles['header__right-container']}>{rightSide}</section>
  </header>
)

Header.propTypes = {
  children: PropTypes.node,
  leftSide: PropTypes.node,
  rightSide: PropTypes.node,
}

Header.defaultProps = {
  children: null,
  leftSide: null,
  rightSide: null,
}

export default Header
