import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const FaceNormal = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 30 25">
    {children}
    <path
      d="M27.1875 14.5C27.1844 17.7068 25.968 20.7936 23.7826 23.1404C21.5972 25.4872 18.6047 26.9201 15.4062 27.1512C15.1072 27.1784 14.8036 27.1875 14.5 27.1875C11.1351 27.1875 7.90795 25.8508 5.52858 23.4714C3.14921 21.092 1.8125 17.8649 1.8125 14.5C1.8125 11.1351 3.14921 7.90795 5.52858 5.52858C7.90795 3.14921 11.1351 1.8125 14.5 1.8125C14.8036 1.8125 15.1072 1.82156 15.4062 1.84875C18.6047 2.07993 21.5972 3.51283 23.7826 5.85962C25.968 8.20641 27.1844 11.2932 27.1875 14.5Z"
      fill="#FCB316"
    />
    <path
      d="M15.4062 27.1521C21.9129 27.1521 27.1875 21.488 27.1875 14.5009C27.1875 7.51377 21.9129 1.84961 15.4062 1.84961C8.89965 1.84961 3.625 7.51377 3.625 14.5009C3.625 21.488 8.89965 27.1521 15.4062 27.1521Z"
      fill="#FFCB29"
    />
    <path
      d="M19.0312 15.1797C20.0323 15.1797 20.8438 13.9625 20.8438 12.4609C20.8438 10.9594 20.0323 9.74219 19.0312 9.74219C18.0302 9.74219 17.2188 10.9594 17.2188 12.4609C17.2188 13.9625 18.0302 15.1797 19.0312 15.1797Z"
      fill="#455B64"
    />
    <path
      d="M9.96875 15.1797C10.9698 15.1797 11.7812 13.9625 11.7812 12.4609C11.7812 10.9594 10.9698 9.74219 9.96875 9.74219C8.96773 9.74219 8.15625 10.9594 8.15625 12.4609C8.15625 13.9625 8.96773 15.1797 9.96875 15.1797Z"
      fill="#455B64"
    />
    <path
      d="M17.6719 19.2578H11.3281C11.2079 19.2578 11.0927 19.2101 11.0077 19.1251C10.9227 19.0401 10.875 18.9249 10.875 18.8047C10.875 18.6845 10.9227 18.5693 11.0077 18.4843C11.0927 18.3993 11.2079 18.3516 11.3281 18.3516H17.6719C17.7921 18.3516 17.9073 18.3993 17.9923 18.4843C18.0773 18.5693 18.125 18.6845 18.125 18.8047C18.125 18.9249 18.0773 19.0401 17.9923 19.1251C17.9073 19.2101 17.7921 19.2578 17.6719 19.2578Z"
      fill="#EF5451"
    />
  </SvgBaseIcon>
)

FaceNormal.propTypes = {
  children: PropTypes.elementType,
}

FaceNormal.defaultProps = {
  children: null,
}

export default memo(FaceNormal)
