import { observer } from 'mobx-react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useRootStore } from 'providers/RootProvider'

const PrivateRoute = observer(({ component: Component, redirectPath, ...rest }) => {
  const { authStore } = useRootStore()
  const location = useLocation()

  if (authStore?.isAuthenticated || authStore?.isLoading) {
    return <Route {...rest} render={(props) => <Component {...props} />} />
  }

  return (
    <Redirect
      to={{
        pathname: redirectPath,
        from: location,
      }}
    />
  )
})

PrivateRoute.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
}

export default PrivateRoute
