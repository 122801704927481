import Model from 'models/Model'

class EvaluationField extends Model {
  constructor(name, value, comment, ...props) {
    super(...props)
    this.name = name
    this.value = value
    this.comment = comment
  }

  static create({ id, name, value, comment } = {}) {
    return new EvaluationField(name, value, comment, id)
  }

  valueOf() {
    const { name, value, comment } = this
    return {
      ...super.valueOf(),
      name,
      value,
      comment,
    }
  }
}

export default EvaluationField
