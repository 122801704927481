import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { PROFILE_VIEW_PERMISSION } from 'permissions'

/* PATHS */
export const PROFILE_SCENE_PATH = '/profile'

/* COMPONENTS */

const ProfileScene = lazy(() =>
  import(
    /* webpackChunkName: "RecruitingProcessSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './ProfileScene'
  )
)

/* ROUTES */
const ProfileSceneRoute = () => (
  <Route
    exact
    path={PROFILE_SCENE_PATH}
    permission={PROFILE_VIEW_PERMISSION}
    component={ProfileScene}
  />
)

export default [ProfileSceneRoute]
