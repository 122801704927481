import { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './versionApp.module.scss'

const VersionApp = ({ version }) =>
  typeof version === 'string' && <div className={styles.versionApp}>{version}</div>

VersionApp.propTypes = {
  version: PropTypes.string,
}

VersionApp.defaultProps = {
  version: null,
}

export default memo(VersionApp)
