import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const CaretDownIcon = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 24 24">
    {children}
    <path d="m13 16-5-5 1-1 4 4 4-4 1 1-5 5Z" fill="currentColor" />
  </SvgBaseIcon>
)

CaretDownIcon.propTypes = {
  children: PropTypes.elementType,
}

CaretDownIcon.defaultProps = {
  children: null,
}

export default memo(CaretDownIcon)
