import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Meta = ({ title, canonicalUrl }) => (
  <Helmet>
    {title && <title>{title}</title>}
    {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
  </Helmet>
)

Meta.propTypes = {
  title: PropTypes.string,
  canonicalUrl: PropTypes.string,
}

Meta.defaultProps = {
  title: null,
  canonicalUrl: null,
}

export default Meta
