import { Route, Switch, Redirect } from 'react-router-dom'
import uuid from 'react-uuid'
import { observer } from 'mobx-react'
import { useRootStore } from 'providers/RootProvider'
import { Error404 } from 'scenes/Errors'
import { LoadingRing } from 'subFramework'
import { HOME_PATH } from './Routes'

const Router = () => {
  const rootStore = useRootStore()
  const {
    plugInStore: { routes },
    permissionStore,
  } = rootStore

  if (permissionStore.isEmpty() || routes == null) {
    return <LoadingRing center />
  }

  return (
    <Switch>
      {routes != null &&
        routes.map((ItemRoute) => {
          const { props } = ItemRoute()
          // eslint-disable-next-line react/prop-types
          const { permission } = props

          return !permission || permissionStore.can(permission) ? (
            <Route key={uuid()} {...props} />
          ) : null
        })}
      <Redirect from="/" to={HOME_PATH} />
      <Route path="*" component={Error404} />
    </Switch>
  )
}

export default observer(Router)
