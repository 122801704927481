import { extendObservable } from 'mobx'

function pluginRoutes({ routes }) {
  if (this.routes == null) {
    extendObservable(this, { routes: [] })
  }

  if (routes != null) {
    routes.forEach((route) => this.routes.push(route))
  }

  return () => {
    this.routes = []
  }
}

export default pluginRoutes
