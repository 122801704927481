import 'util/i18n'
import { observer } from 'mobx-react'
import { setHtmlLang, LANG } from 'util/language'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import RootStoreProvider from 'providers/RootProvider'
import { LOGIN_PATH, RECOVER_PASS_PATH } from 'routing/Routes'
import PrivateRoute from 'routing/PrivateRoute'
import AuthorizedRouter from 'routing/AuthorizedRouter'
import VersionApp from 'presentation/VersionApp'
import ServerDomain from 'presentation/ServerDomain'
import Meta from 'presentation/Meta'

import 'styles/base.scss'
import { ToastConfig } from 'presentation/Toast'
import LoginScene from 'scenes/LoginScene'
import RecoverPassScene from 'scenes/RecoverPassScene'

setHtmlLang(LANG)

const serverDomain = process.env.REACT_APP_SERVER_DOMAIN
const version = process.env.REACT_APP_VERSION

const App = () => (
  <BrowserRouter>
    <ServerDomain domain={serverDomain} />
    <Meta
      title={
        typeof serverDomain === 'string' ? `${serverDomain.toUpperCase()} - Slingshot` : 'Slingshot'
      }
    />
    <RootStoreProvider>
      <Switch>
        <Route exact path={LOGIN_PATH} component={LoginScene} />
        <Route exact path={RECOVER_PASS_PATH} component={RecoverPassScene} />
        <PrivateRoute redirectPath={LOGIN_PATH} component={AuthorizedRouter} />
      </Switch>
    </RootStoreProvider>
    <ToastConfig />
    <VersionApp version={version} />
  </BrowserRouter>
)

export default observer(App)
