import Email from 'models/Email'
import Phone from 'models/Phone'
import SocialNetwork from 'models/SocialNetwork'

class Contact {
  static create(contactItem) {
    const { email, number, url, socialNetwork, type, ...props } = contactItem

    if (type === 'email') {
      return Email.create({ ...Email.parse(email), ...props })
    }

    if (contactItem instanceof Email) {
      return Email.create({ ...props })
    }

    if (type === 'phone' || contactItem instanceof Phone) {
      return Phone.create({ number, ...props })
    }

    if (type === 'socialNetwork' || contactItem instanceof SocialNetwork) {
      return SocialNetwork.create({ url, socialNetwork, ...props })
    }

    return undefined
  }

  static isEmail(contact) {
    return contact instanceof Email
  }

  static isLinkedin(contact) {
    return contact instanceof SocialNetwork && contact.socialNetwork === 'linkedin'
  }

  static isLandline(contact) {
    return contact instanceof Phone && contact.isLandline
  }

  static isMobilePhone(contact) {
    return contact instanceof Phone && contact.isMobile
  }
}

export default Contact
