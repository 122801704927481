import React from 'react'
import PropTypes from 'prop-types'
import Frame from 'presentation/Frame'

function ToastInfo({ title, message }) {
  return <Frame title={title}>{message}</Frame>
}

ToastInfo.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default ToastInfo
