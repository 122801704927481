import { useRootStore } from 'providers/RootProvider'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LogoutIcon from '@mui/icons-material/Logout'

import styles from './logoutButton.module.scss'

function LogoutButton({ onlyIcons }) {
  const { t } = useTranslation()
  const { authStore, permissionStore } = useRootStore()
  const logout = () => {
    authStore.logout()
    permissionStore.clearPermissions()
  }

  return (
    <button type="button" className={styles.logout__button} onClick={logout}>
      {onlyIcons ? '' : t('Logout')}
      <span className={styles.icon__wrapper}>
        <LogoutIcon
          className={styles.logout__icon}
          focusable="false"
          aria-hidden="true"
          size={24}
        />
      </span>
    </button>
  )
}

LogoutButton.propTypes = {
  onlyIcons: PropTypes.bool,
}

LogoutButton.defaultProps = {
  onlyIcons: false,
}

export default LogoutButton
