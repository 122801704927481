import FaceHappy from 'assets/icons/FaceHappy'
import FaceNormal from 'assets/icons/FaceNormal'
import FaceSad from 'assets/icons/FaceSad'
import { rangeOptions } from './utils'

export const EMAIL = 'email'
export const URL = 'url'

export const APPLICANT_ORIGIN_OPTIONS = {
  CONSULTING: ['Consultant', 'Consultora'],
  REFERRED: ['Referred', 'Referido'],
  LINKEDIN: ['LinkedIn', 'Linkedin'],
  OTHER: ['Other', 'Otro'],
}

export const MESSAGE_TYPE = {
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  ERROR: 'ERROR',
}

export const POSITIONS = {
  ARCHITECT: 'Arquitecto',
  SALESFORCE_ARCHITEC: 'Arquitecto/a de Soluciones Salesforce',
  DEMAND_GENERATION_LEAD: 'Demand Generation Lead',
  DESIGN: 'Diseño',
  NET: '.Net',
  NETCORE: 'Netcore',
  NODE: 'Node',
  PHP: 'PHP',
  PM: 'PM',
  PRODUCT: 'Producto',
  REACT: 'React',
  REACT_NATIVE: 'React Native',
}

export const EVENTS = {
  START: 'start',
  CONTACT: 'contact',
  CV_RECEIVED: 'cvReceived',
  HR_INTERVIEW: 'hrInterview',
  TECHNICAL_INTERVIEW: 'technicalInterview',
  TECHNICAL_INTERVIEW_II: 'technicalInterviewII',
  OFFER_EVALUATION: 'offerEvaluation',
  FORMAL_OFFER: 'formalOffer',
  PREOCCUPATIONAL: 'preoccupational',
  SCHEDULED_ENTRANCE: 'scheduledEntrance',
  END: 'End',
  PM_INTERVIEW: 'projectManagementInterview',
  SPECIALIST_INTERVIEW: 'specialistInterviewII',
  PSYCHOTECHNICAL: 'psychotechnical',
  CHALLENGE_SENT: 'challengeSent',
  CHALLENGE_EVALUATION: 'challengeInEvaluation',
  TECHNICAL_DEVOLUTION_RECEIVED: 'technicalDevolutionReceived',
  BOOSTER: 'booster',
}

export const GENDER_OPTIONS = {
  es: [
    { id: 'male', value: 'Masculino' },
    { id: 'female', value: 'Femenino' },
    { id: 'nonbinary', value: 'No Binario' },
  ],
  en: [
    { id: 'male', value: 'Male' },
    { id: 'female', value: 'Female' },
    { id: 'nonbinary', value: 'Non Binary' },
  ],
}

export const SENIORITY_OPTIONS = [
  { id: 0, type: 'trainee', value: 'Trainee' },
  { id: 1, type: 'junior', value: 'Junior' },
  { id: 2, type: 'juniorPlus', value: 'Junior +' },
  { id: 3, type: 'semiseniorMinus', value: 'Semisenior -' },
  { id: 4, type: 'semisenior', value: 'Semisenior' },
  { id: 5, type: 'semiseniorPlus', value: 'Semisenior +' },
  { id: 6, type: 'senior', value: 'Senior' },
  { id: 7, type: 'tl', value: 'TL' },
]

export const CALIFICATION_OPTIONS = [
  { id: 'no_way', value: 'No, ni loco!' },
  { id: 'no', value: 'No' },
  { id: 'yes', value: 'Si' },
  { id: 'absolutely', value: 'Si, a full!' },
]

export const ATTITUDE_OPTIONS = [
  { label: <FaceHappy width={24} height={24} />, value: '1' },
  { label: <FaceNormal width={24} height={24} />, value: '0' },
  { label: <FaceSad width={24} height={24} />, value: '-1' },
]

// TODO: Estas constantes con valor traducido no estan bien, hay que reveer como hacer esto
export const APPROVE_OPTIONS = [
  { id: true, value: 'Si' },
  { id: false, value: 'No' },
]

export const EXPERIENCE_OPTIONS = rangeOptions({ length: 5, Type: String })
export const KNOWLEDGE_OPTIONS = rangeOptions({ length: 5, Type: String })
export const MATCH_OPTIONS = rangeOptions({ length: 5, Type: String })
export const MANAGEMENT_OPTIONS = rangeOptions({ length: 5, Type: String })

export const EVALUATION_FIELDS = {
  CONTEXT: 'context',
  SENIORITY: 'seniority',
  CALIFICATION: 'calification',
  CALIFICATION_COMMENTS: 'calificationComments',
  EXPERIENCE: 'experience',
  KNOWLEDGE: 'knowledge',
  MANAGEMENT: 'management',
  MATCH: 'match',
  ATTITUDE: 'attitude',
  TECHNOLOGIES: 'technologies',
  APPROVED: 'approved',
}
