import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import { useRootStore } from 'providers/RootProvider'
import { HOME_PATH, RECOVER_PASS_PATH } from 'routing/Routes'
import Meta from 'presentation/Meta'
import InputWrapper from 'subFramework/presentation/InputWrapper'
import Button from 'subFramework/presentation/Button'
import Logotype from 'presentation/Logotype'
import useToast from 'util/useToast'
import { generateURLfromLocation } from 'util/urlGenerators'
import { MESSAGE_TYPE } from 'util/constants'
import LoginFormStore from './LoginFormStore'

import styles from './loginScene.module.scss'

const LoginScene = () => {
  const { t } = useTranslation('Login')
  const errorToast = useToast({ type: MESSAGE_TYPE.ERROR })

  const history = useHistory()

  const { authStore, permissionStore } = useRootStore()

  const [loginFormStore] = useState(new LoginFormStore(authStore))
  const { email, password, errors } = loginFormStore

  const { location } = history
  const { from } = location

  useEffect(() => {
    if (authStore.isAuthenticated) {
      history.push(from?.pathname == null ? HOME_PATH : generateURLfromLocation(from))
      permissionStore.loadPermissions((permissions) => authStore.updatePermissions(permissions))
    }
  }, [authStore.isAuthenticated, authStore.dashboardRoute])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      loginFormStore.signIn(errorToast)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Meta title="Slingshot" />
        <div className={styles.column}>
          <div className={styles.logo}>
            <Logotype />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.inputs}>
            <InputWrapper
              inputStore={email}
              wrapperClassName={styles['input__user--wrapper']}
              className={styles.input__user}
              onChange={({ target }) => email.setValue(target.value)}
              onKeyPress={handleKeyPress}
              label={t('EnterUser')}
              required
            />
            <InputWrapper
              inputStore={password}
              wrapperClassName={styles['input__password--wrapper']}
              className={styles.input__password}
              onChange={({ target }) => password.setValue(target.value)}
              onKeyPress={handleKeyPress}
              label={t('EnterPassword')}
              type="password"
              required
            />
            <div className={styles.errors}>
              {errors.map((error) => (
                <small key={uuid()}>{error}</small>
              ))}
            </div>
            <div className={styles.buttons}>
              <Button
                fullWidth
                big
                label={t('Login')}
                onClick={() => loginFormStore.signIn(errorToast)}
                as="button"
              />
              <Button
                className={styles.recoveryButton}
                label={t('PasswordRecovery')}
                onClick={() => history.push(RECOVER_PASS_PATH)}
                as="button"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(LoginScene)
