import { ADMIN_PANEL_VIEW_PERMISSION } from 'permissions'
import { lazy } from 'react'
import { Route } from 'react-router-dom'

/* PATHS */
export const ADMIN_PANEL_SCENE_PATH = '/admin_panel'

/* COMPONENTS */
const AdminPanel = lazy(() =>
  import(
    /* webpackChunkName: "StatisticsSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './AdminPanelScene'
  )
)

/* ROUTES */
const AdminPanelRoute = () => (
  <Route
    exact
    path={ADMIN_PANEL_SCENE_PATH}
    permission={ADMIN_PANEL_VIEW_PERMISSION}
    component={AdminPanel}
  />
)

export default [AdminPanelRoute]
