import { extendObservable } from 'mobx'
import URL from 'url'

function isActive(match, location) {
  const { search } = location
  const { routePath, exactQuery, state } = this
  const { query } = URL.parse(routePath)

  const isEqual = query == null && search === ''

  const queryMatch = exactQuery ? isEqual || search.includes(query) : true

  if (match == null || !queryMatch) {
    return false
  }

  Object.defineProperty(location, 'state', {
    value: { ...state, ...location?.state },
  })

  return true
}

function pluginMenu({ menu: menuItem }) {
  if (this.menu == null) {
    extendObservable(this, { menu: [] })
  }

  if (menuItem != null) {
    this.menu = this.menu.filter((currentMenu) => currentMenu?.id !== menuItem?.id)

    const subMenu = Array.isArray(menuItem.subMenu)
      ? menuItem.subMenu.map((subMenuItem) => ({
          ...subMenuItem,
          isActive: isActive.bind({ ...subMenuItem }),
        }))
      : undefined

    this.menu.push({
      ...menuItem,
      subMenu,
      isActive: isActive.bind({ ...menuItem }),
    })
  }

  return () => {
    this.menu = []
  }
}

export default pluginMenu
