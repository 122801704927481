import Model from 'models/Model'

class EmailAddress extends Model {
  constructor(username, domain, kind, ...props) {
    super(...props)

    this.kind = kind
    this.username = username
    this.domain = domain
  }

  static create({ username, domain, kind, id, createDate, updateDate }) {
    return new EmailAddress(username, domain, kind, id, createDate, updateDate)
  }

  static parse(email) {
    if (typeof email !== 'string') {
      return new EmailAddress()
    }

    const [username, domain] = email.split('@')
    return new EmailAddress(username, domain)
  }

  toString() {
    return this.username != null && this.domain != null
      ? `${this.username}@${this.domain}`
      : undefined
  }

  valueOf() {
    const { kind } = this
    return { ...super.valueOf(), kind, email: this.toString() }
  }
}

export default EmailAddress
