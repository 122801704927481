import List from 'models/List'
import Model from 'models/Model'
import EvaluationField from './EvaluationField'

class Evaluation extends Model {
  constructor(name, calification, comments, fields = [], ...props) {
    super(...props)
    this.name = name
    this.calification = calification
    this.comments = comments
    this.fields = List.from(fields, null, EvaluationField)
  }

  static create({ id, name, calification, comments, fields }) {
    return new Evaluation(name, calification, comments, fields, id)
  }

  valueOf() {
    const { name, fields, calification, comments } = this
    return {
      ...super.valueOf(),
      name,
      fields: fields.map((field) => field.valueOf()),
      calification,
      comments,
    }
  }
}

export default Evaluation
