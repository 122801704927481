import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const RoundedExclamationIcon = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 34 34">
    {children}
    <path d="M18.1333 23.8V22.6667H15.8667V23.8H18.1333ZM15.8667 23.8227V24.956H18.1333V23.8227H15.8667ZM15.8667 9.06667V18.1333H18.1333V9.06667H15.8667ZM15.8667 23.8V23.8227H18.1333V23.8H15.8667ZM17 31.7333C13.0925 31.7333 9.345 30.1811 6.58196 27.418C3.81892 24.655 2.26667 20.9075 2.26667 17H0C0 21.5087 1.79107 25.8327 4.97918 29.0208C8.1673 32.2089 12.4913 34 17 34V31.7333ZM31.7333 17C31.7333 18.9348 31.3522 20.8507 30.6118 22.6382C29.8714 24.4257 28.7862 26.0499 27.418 27.418C26.0499 28.7862 24.4257 29.8714 22.6382 30.6118C20.8507 31.3522 18.9348 31.7333 17 31.7333V34C21.5087 34 25.8327 32.2089 29.0208 29.0208C32.2089 25.8327 34 21.5087 34 17H31.7333ZM17 2.26667C18.9348 2.26667 20.8507 2.64776 22.6382 3.38817C24.4257 4.12859 26.0499 5.21384 27.418 6.58196C28.7862 7.95008 29.8714 9.57427 30.6118 11.3618C31.3522 13.1493 31.7333 15.0652 31.7333 17H34C34 12.4913 32.2089 8.1673 29.0208 4.97918C25.8327 1.79107 21.5087 0 17 0V2.26667ZM17 0C12.4913 0 8.1673 1.79107 4.97918 4.97918C1.79107 8.1673 0 12.4913 0 17H2.26667C2.26667 13.0925 3.81892 9.345 6.58196 6.58196C9.345 3.81892 13.0925 2.26667 17 2.26667V0Z" />
  </SvgBaseIcon>
)

RoundedExclamationIcon.propTypes = {
  children: PropTypes.elementType,
}

RoundedExclamationIcon.defaultProps = {
  children: null,
}

export default memo(RoundedExclamationIcon)
