import { STATISTICS_VIEW_PERMISSION } from 'permissions'
import { lazy } from 'react'
import { Route } from 'react-router-dom'

/* PATHS */
export const STATISTICSSCENEPATH = '/statistics'

/* COMPONENTS */
const Statistics = lazy(() =>
  import(
    /* webpackChunkName: "StatisticsSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './StatisticsScene'
  )
)

/* ROUTES */
const StatisticsRoute = () => (
  <Route
    exact
    path={STATISTICSSCENEPATH}
    permission={STATISTICS_VIEW_PERMISSION}
    component={Statistics}
  />
)

export default [StatisticsRoute]
