/* eslint-disable import/prefer-default-export */
export const PROCESS_STATUS = Object.freeze({
  IN_PROGRESS: 'in_progress',
  FINALIZED: 'finalized',
  CANCELED: 'canceled',
})

export const EVENT_STATUS = Object.freeze({
  IN_PROGRESS: 'in_progress',
  PENDING: 'pending',
  FINALIZED: 'finalized',
  CANCELED: 'canceled',
  UNDEFINED: 'undefined',
})

// TODO: Esto en el futuro deberia venir desde la API
export const EVENT_TYPE = Object.freeze({
  BOOSTER: 'booster',
  CANCELED: 'canceled',
  CHALLENGEINEVALUATION: 'challengeInEvaluation',
  CHALLENGESENT: 'challengeSent',
  CONTACT: 'contact',
  CVRECEIVED: 'cvReceived',
  FINALIZED: 'end',
  FORMALOFFER: 'formalOffer',
  HRINTERVIEW: 'hrInterview',
  PMINTERVIEW: 'projectManagementInterview',
  OFFEREVALUATION: 'offerEvaluation',
  PREOCCUPATIONAL: 'preoccupational',
  PROJECTMANAGEMENTINTERVIEW: 'projectManagementInterview',
  PSYCHOTECHNICAL: 'psychotechnical',
  SCHEDULEDENTRANCE: 'scheduledEntrance',
  SPECIALISTINTERVIEW2: 'specialistInterviewII',
  SPECIALISTINTERVIEW: 'specialistInterview',
  STARTED: 'start',
  TECHNICALDEVOLUTIONRECEIVED: 'technicalDevolutionReceived',
  TECHNICALINTERVIEW2: 'technicalInterviewII',
  TECHNICALINTERVIEW: 'technicalInterview',
})

export const API_ERRORS_TYPE = Object.freeze({
  VALIDATION_EXCEPTION: 'VALIDATION_EXCEPTION',
  UNATHORIZED: 'unauthorized',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
})

export const ROLE = Object.freeze({
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  TECHNICAL_EVALUATOR: 'technical_evaluator',
})
