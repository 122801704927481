import { COMPARATIVE_VIEW_PERMISSION } from 'permissions'
import { lazy } from 'react'
import { Route } from 'react-router-dom'

/* PATHS */
export const COMPARATIVESCENEPATH = '/comparative'

/* COMPONENTS */
const Comparative = lazy(() =>
  import(
    /* webpackChunkName: "ComparativeSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './ComparativeScene'
  )
)

/* ROUTES */
const ComparativeRoute = () => (
  <Route
    exact
    path={COMPARATIVESCENEPATH}
    permission={COMPARATIVE_VIEW_PERMISSION}
    component={Comparative}
  />
)

export default [ComparativeRoute]
