class List extends Array {
  static from(list = [], dataMap = null, model = null) {
    function ListModelMap(item) {
      if (dataMap instanceof Function) {
        return this.create(dataMap(item))
      }

      if (this.create instanceof Function) {
        return this.create(item)
      }

      return item
    }

    return new List(...Array.from(list, ListModelMap, model))
  }

  valueOf() {
    return [...this].map((item) => item.valueOf())
  }
}

export default List
