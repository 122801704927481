import i18next from 'i18next'
import StatisticsIcon from 'assets/icons/StatisticsIcon'
import StatisticsRoutes, { STATISTICSSCENEPATH } from './StatisticsRoutes'

/**
 * The Submenu properties.
 *
 * @typedef {object} Submenu
 * @property {React.ReactElement} icon
 * @property {string} display
 * @property {string} id
 * @property {string} routePath
 * @property {boolean} exact
 * @property {boolean} exactQuery
 * @property {object} state
 */

/**
 * The menu properties.
 *
 * @typedef {object} Menu
 * @property {React.ReactElement} icon
 * @property {string} display
 * @property {string} id
 * @property {Submenu[]} submenu
 * @property {string} routePath
 * @property {boolean} exact
 * @property {boolean} exactQuery
 * @property {object} state
 */

/**
 * ApplicantScene Plugin module.
 * @module ApplicantScene
 *
 * @param {string} id
 * @param {Route[]} routes
 * @param {Menu} menu
 */

export default {
  id: 'statistics',
  menu: {
    icon: StatisticsIcon,
    display: i18next.t('Statistics:Menu:Statistics'),
    id: 'Statistics',
    routePath: STATISTICSSCENEPATH,
    state: {
      menu: i18next.t('Statistics:Menu:Statistics'),
    },
  },
  routes: StatisticsRoutes,
}
