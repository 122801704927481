import PropTypes from 'prop-types'
import styles from './footer.module.scss'

const Footer = ({ children, leftSide, rightSide, ...props }) => (
  <footer className={styles.wrapper} {...props}>
    <section className={styles['footer__left-container']}>{leftSide}</section>
    <section className={styles['footer__middle-container']}>{children}</section>
    <section className={styles['footer__right-container']}>{rightSide}</section>
  </footer>
)

Footer.propTypes = {
  children: PropTypes.node,
  leftSide: PropTypes.node,
  rightSide: PropTypes.node,
}

Footer.defaultProps = {
  children: null,
  leftSide: null,
  rightSide: null,
}

export default Footer
