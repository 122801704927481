import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const FaceHappy = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 30 25">
    {children}
    <path
      d="M27.1875 14.5C27.1844 17.7068 25.968 20.7936 23.7826 23.1404C21.5972 25.4872 18.6047 26.9201 15.4062 27.1512C15.1072 27.1784 14.8036 27.1875 14.5 27.1875C11.1351 27.1875 7.90795 25.8508 5.52858 23.4714C3.14921 21.092 1.8125 17.8649 1.8125 14.5C1.8125 11.1351 3.14921 7.90795 5.52858 5.52858C7.90795 3.14921 11.1351 1.8125 14.5 1.8125C14.8036 1.8125 15.1072 1.82156 15.4062 1.84875C18.6047 2.07993 21.5972 3.51283 23.7826 5.85962C25.968 8.20641 27.1844 11.2932 27.1875 14.5Z"
      fill="#FCB316"
    />
    <path
      d="M15.4062 27.1521C21.9129 27.1521 27.1875 21.488 27.1875 14.5009C27.1875 7.51377 21.9129 1.84961 15.4062 1.84961C8.89965 1.84961 3.625 7.51377 3.625 14.5009C3.625 21.488 8.89965 27.1521 15.4062 27.1521Z"
      fill="#FFCB29"
    />
    <path
      d="M14.5006 19.2973C12.6494 19.2994 10.8359 18.7736 9.27289 17.7816C9.22194 17.75 9.17777 17.7086 9.14293 17.6598C9.1081 17.611 9.08329 17.5558 9.06995 17.4973C9.05661 17.4388 9.05501 17.3783 9.06522 17.3193C9.07544 17.2602 9.09728 17.2037 9.12948 17.1531C9.16168 17.1026 9.20359 17.0589 9.25279 17.0246C9.302 16.9904 9.35751 16.9662 9.41611 16.9536C9.47472 16.9409 9.53525 16.94 9.5942 16.9509C9.65316 16.9618 9.70936 16.9843 9.75955 17.0171C11.1778 17.9151 12.822 18.3918 14.5006 18.3918C16.1792 18.3918 17.8234 17.9151 19.2416 17.0171C19.2918 16.9843 19.348 16.9618 19.407 16.9509C19.4659 16.94 19.5265 16.9409 19.5851 16.9536C19.6437 16.9662 19.6992 16.9904 19.7484 17.0246C19.7976 17.0589 19.8395 17.1026 19.8717 17.1531C19.9039 17.2037 19.9257 17.2602 19.936 17.3193C19.9462 17.3783 19.9446 17.4388 19.9312 17.4973C19.9179 17.5558 19.8931 17.611 19.8583 17.6598C19.8234 17.7086 19.7792 17.75 19.7283 17.7816C18.1653 18.7736 16.3518 19.2994 14.5006 19.2973Z"
      fill="#EF5451"
    />
    <path
      d="M19.0312 15.1328C20.0323 15.1328 20.8438 13.9156 20.8438 12.4141C20.8438 10.9125 20.0323 9.69531 19.0312 9.69531C18.0302 9.69531 17.2188 10.9125 17.2188 12.4141C17.2188 13.9156 18.0302 15.1328 19.0312 15.1328Z"
      fill="#455B64"
    />
    <path
      d="M9.96875 15.1328C10.9698 15.1328 11.7812 13.9156 11.7812 12.4141C11.7812 10.9125 10.9698 9.69531 9.96875 9.69531C8.96773 9.69531 8.15625 10.9125 8.15625 12.4141C8.15625 13.9156 8.96773 15.1328 9.96875 15.1328Z"
      fill="#455B64"
    />
  </SvgBaseIcon>
)

FaceHappy.propTypes = {
  children: PropTypes.elementType,
}

FaceHappy.defaultProps = {
  children: null,
}

export default memo(FaceHappy)
