// import i18next from 'i18next'
import { observable, makeObservable } from 'mobx'
import { AsyncStore } from 'subFramework'
import InputStore from 'subFramework/stores/InputStore'

class RecoverPassFormStore extends AsyncStore {
  constructor(authStore) {
    super()

    this.email = new InputStore()
    this.isLoading = false
    this.authStore = authStore

    makeObservable(this, {
      // observables
      email: observable,
    })
  }

  recoverPassword(onSuccess, onError) {
    this.preRequest()
    this.clearError()

    this.authStore.authService
      .recoverPassword(this.email.value)
      .then(() => {
        this.onSuccessRequest()
        onSuccess()
      })
      .catch(({ isValidationError, fields, message }) => {
        if (isValidationError) {
          const fieldNames = Object.keys(fields)

          fieldNames.forEach((fieldName) => this[fieldName].setError(fields[fieldName][0]))
        } else {
          this.onErrorRequest(message)
          onError(null, message)
        }
      })
      .finally(() => this.finishRequest())
  }
}

export default RecoverPassFormStore
