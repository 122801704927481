import Model from 'models/Model'

class Phone extends Model {
  constructor(number, kind = 'landLine', ...props) {
    super(...props)

    this.number = number
    this.kind = kind
  }

  get isLandline() {
    return this.kind === 'landline'
  }

  get isMobile() {
    return this.kind === 'mobile'
  }

  static create({ number, kind, id, createDate, updateDate }) {
    return new Phone(number, kind, id, createDate, updateDate)
  }

  toString() {
    return this.number != null ? String(this.number) : undefined
  }

  toWhatsappLink() {
    if (!this.number) return null

    const formattedNumber = this.toString().replace(/[-\s()]/g, '')

    return `https://wa.me/${formattedNumber}`
  }

  valueOf() {
    const { number, kind } = this
    return { ...super.valueOf(), number, kind }
  }
}

export default Phone
