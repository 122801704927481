import Model from 'models/Model'
import moment from 'moment'

class CancelReasons extends Model {
  constructor(comments, reason, responsible, origin, dateToContact, ...props) {
    super(...props)

    this.comments = comments
    this.reason = reason
    this.responsible = responsible
    this.origin = origin
    this.dateToContact = moment(dateToContact)
  }

  static create({
    comments,
    reason,
    responsible,
    origin,
    dateToContact,
    id,
    createDate,
    updateDate,
  } = {}) {
    return new CancelReasons(
      comments,
      reason,
      responsible,
      origin,
      dateToContact,
      id,
      createDate,
      updateDate
    )
  }

  valueOf() {
    const { comments, reason, responsible, origin, dateToContact } = this
    return { ...super.valueOf(), comments, reason, responsible, origin, dateToContact }
  }
}

export default CancelReasons
