import Permission from 'models/Permission'
import List from 'models/List'

const transformerAPI = (permission) => ({
  id: permission,
  value: true,
})

class PermissionListDTO {
  static fromAPI(dataFromAPI) {
    return List.from(dataFromAPI, transformerAPI, Permission)
  }
}

export default PermissionListDTO
