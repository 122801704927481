import { observable, action, makeObservable, runInAction } from 'mobx'
import AsyncStore from 'subFramework/stores/AsyncStore'
import PermissionListAPIDTO from 'dto/PermissionListAPIDTO'
import PermissionService from 'services/PermissionService'
import capitalize from 'util/utils'

class PermissionStore extends AsyncStore {
  constructor() {
    super()

    this.permissions = []

    this.permissionService = new PermissionService()

    makeObservable(this, {
      // observables
      permissions: observable,
      // actions
      loadPermissions: action,
      clearPermissions: action,
    })

    this.loadPermissions()
  }

  clearPermissions() {
    this.permissions = []
  }

  async loadPermissions(onSuccess) {
    this.preRequest()

    try {
      const { data } = await this.permissionService.getPermissionsList()

      runInAction(() => {
        this.permissions = PermissionListAPIDTO.fromAPI(data)

        if (onSuccess) onSuccess(data)
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  can(...params) {
    if (this.permissions == null) {
      return false
    }

    if (params.length === 1) {
      const [idQuery] = params
      return this.permissions.find(({ id }) => idQuery === id)?.value
    }

    if (params.length === 2) {
      const [namespaceQuery, permissionQuery] = params

      return this.permissions.find(
        ({ namespace, permission }) =>
          namespaceQuery === namespace && permissionQuery === permission
      )?.value
    }

    throw SyntaxError('invalid parameters')
  }

  getPermissions(namespace) {
    const permissionsMap = {}

    this.permissions.forEach((permission) => {
      const { namespace: currentNamespace, permission: currentPermissionName, value } = permission

      if (namespace === currentNamespace) {
        permissionsMap[`can${capitalize(currentPermissionName)}`] = value
      }
    })

    return permissionsMap
  }

  getEventPermissions(eventName) {
    return {
      ...this.getPermissions(`event.${eventName}`),
      canShow: true, // by default is true for all but If it's necessary change it for a new logic.
    }
  }

  getProcessPermissions() {
    return this.getPermissions('process')
  }

  getApplicantPermissions() {
    return this.getPermissions('applicant')
  }

  getProfilePermissions() {
    return this.getPermissions('profile')
  }

  isEmpty() {
    return this.permissions.length === 0
  }
}

export default PermissionStore
