import subFrameworkAutStore from 'subFramework/stores/AuthStore'

class AuthStore extends subFrameworkAutStore {
  static async create(token) {
    const newAuthStore = new AuthStore()

    if (token) {
      await newAuthStore.loginWithToken(token)
      return newAuthStore
    }

    return newAuthStore
  }
}

export default AuthStore
