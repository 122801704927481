import React from 'react'
import { ToastContainer /* , toast */ } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import styles from './toast.module.scss'

function ToastConfig() {
  return (
    <ToastContainer
      position="bottom-right"
      className={styles.toast__container}
      toastClassName={styles.toast__toast}
      bodyClassName={styles.toast__body}
      closeButton={false}
      autoClose={7500}
      hideProgressBar
    />
  )
}

export default ToastConfig
