import Model from 'models/Model'

class Permission extends Model {
  constructor(id, createDate, updateDate, namespaceParam, permissionParam, value) {
    super(id, createDate, updateDate)

    const { namespace, permission } = Permission.parseId(id)

    this.namespace = namespaceParam ?? namespace
    this.permission = permissionParam ?? permission
    this.value = value
  }

  static parseId(id = []) {
    const splitIndex = id.lastIndexOf('.')
    const namespace = id.slice(0, splitIndex)
    const permission = id.slice(splitIndex + 1)
    return { namespace, permission }
  }

  static create({ id, createDate, updateDate, namespace, permission, value }) {
    return new Permission(id, createDate, updateDate, namespace, permission, value)
  }

  valueOf() {
    const { namespace, permission, value } = this
    return { ...super.valueOf(), namespace, permission, value }
  }
}

export default Permission
