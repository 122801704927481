import { PROCESS_VIEW_PERMISSION } from 'permissions'
import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { RECRUITING_PROCESS_PATH } from 'routing/Routes'

/* PATHS */

/* COMPONENTS */

const RecruitingProcessScene = lazy(() =>
  import(
    /* webpackChunkName: "RecruitingProcessSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './RecruitingProcessScene'
  )
)

/* ROUTES */
const RecruitingProcessSceneRoute = () => (
  <Route
    exact
    path={RECRUITING_PROCESS_PATH}
    permission={PROCESS_VIEW_PERMISSION}
    component={RecruitingProcessScene}
  />
)

export default [RecruitingProcessSceneRoute]
