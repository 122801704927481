import i18next from 'i18next'
import { API_ERRORS_TYPE } from 'models/RecruitingProcess'

const t = i18next.getFixedT(null, 'Common', 'Errors')

class RequestError extends Error {
  constructor({ code, message, fields, status }) {
    super(message || undefined)

    this.code = code
    this.fields = fields
    this.status = status
  }

  get isValidationError() {
    return this.code === API_ERRORS_TYPE.VALIDATION_EXCEPTION
  }

  get isNotFoundError() {
    return this.code === API_ERRORS_TYPE.PAGE_NOT_FOUND
  }

  get isUnauthorizedError() {
    return this.code === API_ERRORS_TYPE.UNATHORIZED
  }

  get details() {
    switch (this.code) {
      case API_ERRORS_TYPE.UNATHORIZED:
        return { title: t('unathorizedTitle'), message: t('unathorizedMessage') }
      case API_ERRORS_TYPE.VALIDATION_EXCEPTION:
        return { title: t('validationErrorTitle'), message: t('validationErrorMessage') }
      case API_ERRORS_TYPE.PAGE_NOT_FOUND:
        return { title: t('notFoundErrorTitle'), message: t('notFoundErrorMessage') }
      default:
        return { title: null, message: this.message }
    }
  }
}

export default RequestError
