import React from 'react'
import PropTypes from 'prop-types'
import Frame from 'presentation/Frame'
import { MESSAGE_TYPE } from 'util/constants'

function ToastError({ title, message }) {
  return (
    <Frame type={MESSAGE_TYPE.ERROR} title={title}>
      {message}
    </Frame>
  )
}

ToastError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default ToastError
