import { memo } from 'react'
import PropTypes from 'prop-types'
import SvgBaseIcon from './SvgBaseIcon'

const FaceSad = ({ children, ...props }) => (
  <SvgBaseIcon {...props} viewBox="0 0 30 25">
    {children}
    <path
      d="M14.5 27.1875C21.5071 27.1875 27.1875 21.5071 27.1875 14.5C27.1875 7.49289 21.5071 1.8125 14.5 1.8125C7.49289 1.8125 1.8125 7.49289 1.8125 14.5C1.8125 21.5071 7.49289 27.1875 14.5 27.1875Z"
      fill="#FCB316"
    />
    <path
      d="M15.4062 27.1521C21.9129 27.1521 27.1875 21.488 27.1875 14.5009C27.1875 7.51377 21.9129 1.84961 15.4062 1.84961C8.89965 1.84961 3.625 7.51377 3.625 14.5009C3.625 21.488 8.89965 27.1521 15.4062 27.1521Z"
      fill="#FFCB29"
    />
    <path
      d="M19.7283 18.4688C18.1644 17.4788 16.3515 16.9531 14.5006 16.9531C12.6497 16.9531 10.8368 17.4788 9.27289 18.4688C9.22194 18.5004 9.17777 18.5418 9.14293 18.5906C9.1081 18.6394 9.08329 18.6947 9.06995 18.7531C9.05661 18.8116 9.05501 18.8721 9.06522 18.9312C9.07544 18.9902 9.09728 19.0467 9.12948 19.0973C9.16168 19.1478 9.20359 19.1915 9.25279 19.2258C9.302 19.26 9.35751 19.2842 9.41611 19.2968C9.47472 19.3095 9.53525 19.3104 9.5942 19.2995C9.65316 19.2886 9.70936 19.2661 9.75955 19.2333C11.1778 18.3353 12.822 17.8586 14.5006 17.8586C16.1792 17.8586 17.8234 18.3353 19.2416 19.2333C19.2918 19.2661 19.348 19.2886 19.407 19.2995C19.4659 19.3104 19.5265 19.3095 19.5851 19.2968C19.6437 19.2842 19.6992 19.26 19.7484 19.2258C19.7976 19.1915 19.8395 19.1478 19.8717 19.0973C19.9039 19.0467 19.9257 18.9902 19.936 18.9312C19.9462 18.8721 19.9446 18.8116 19.9312 18.7531C19.9179 18.6947 19.8931 18.6394 19.8583 18.5906C19.8234 18.5418 19.7792 18.5004 19.7283 18.4688Z"
      fill="#EF5451"
    />
    <path
      d="M19.0312 15.1328C20.0323 15.1328 20.8438 13.9156 20.8438 12.4141C20.8438 10.9125 20.0323 9.69531 19.0312 9.69531C18.0302 9.69531 17.2188 10.9125 17.2188 12.4141C17.2188 13.9156 18.0302 15.1328 19.0312 15.1328Z"
      fill="#455B64"
    />
    <path
      d="M9.96875 15.1328C10.9698 15.1328 11.7812 13.9156 11.7812 12.4141C11.7812 10.9125 10.9698 9.69531 9.96875 9.69531C8.96773 9.69531 8.15625 10.9125 8.15625 12.4141C8.15625 13.9156 8.96773 15.1328 9.96875 15.1328Z"
      fill="#455B64"
    />
  </SvgBaseIcon>
)

FaceSad.propTypes = {
  children: PropTypes.elementType,
}

FaceSad.defaultProps = {
  children: null,
}

export default memo(FaceSad)
