import Model from 'models/Model'
import { EVENT_STATUS } from './Constants'
import Evaluation from '../Evaluation'

class Event extends Model {
  constructor(
    name,
    date,
    comments,
    sensitiveComment,
    intendedRemuneration,
    offeredRemuneration,
    compensation,
    type,
    expirationDate,
    evaluation,
    evaluationResultLink,
    status,
    order,
    responsible,
    cancelReasons,
    cv,
    ...props
  ) {
    super(...props)

    this.name = name
    this.date = date
    this.comments = comments
    this.sensitiveComment = sensitiveComment
    this.intendedRemuneration = intendedRemuneration
    this.offeredRemuneration = offeredRemuneration
    this.compensation = compensation
    this.type = type
    this.expirationDate = expirationDate
    this.evaluation =
      !evaluation || evaluation instanceof Evaluation ? evaluation : Evaluation.create(evaluation)
    this.evaluationResultLink = evaluationResultLink
    this.status = status
    this.order = order
    this.responsible = responsible
    this.cancelReasons = cancelReasons
    this.cv = cv
  }

  static create({
    name,
    date,
    comments,
    sensitiveComment,
    intendedRemuneration,
    offeredRemuneration,
    compensation,
    type,
    expirationDate,
    evaluation,
    evaluationResultLink,
    status,
    order,
    responsible,
    cancelReasons,
    cv,
    id,
    createDate,
    updateDate,
  } = {}) {
    return new Event(
      name,
      date,
      comments,
      sensitiveComment,
      intendedRemuneration,
      offeredRemuneration,
      compensation,
      type,
      expirationDate,
      evaluation,
      evaluationResultLink,
      status,
      order,
      responsible,
      cancelReasons,
      cv,
      id,
      createDate,
      updateDate
    )
  }

  valueOf() {
    const {
      name,
      date,
      comments,
      sensitiveComment,
      intendedRemuneration,
      offeredRemuneration,
      compensation,
      type,
      expirationDate,
      evaluation,
      evaluationResultLink,
      status,
      order,
      responsible,
      cancelReasons,
      cv,
    } = this
    return {
      ...super.valueOf(),
      name,
      date,
      comments,
      sensitiveComment,
      intendedRemuneration,
      offeredRemuneration,
      compensation,
      type,
      expirationDate,
      evaluation: evaluation.valueOf(),
      evaluationResultLink,
      status,
      order,
      responsible,
      cancelReasons,
      cv,
    }
  }

  isInProgress() {
    return this.status === EVENT_STATUS.IN_PROGRESS
  }

  isPending() {
    return this.status === EVENT_STATUS.PENDING
  }

  isCanceled() {
    return this.status === EVENT_STATUS.CANCELED
  }

  isUndefined() {
    return this.status === EVENT_STATUS.UNDEFINED
  }

  isFinalized() {
    return this.status === EVENT_STATUS.FINALIZED
  }

  hasValidDate() {
    return this.date.isValid?.()
  }

  isLastEvent() {
    return this.isInProgress() || this.hasValidDate()
  }
}

export default Event
